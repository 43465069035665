import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import GroupProfile from "../views/groupProfile.vue";
import Solution from "@/views/solution.vue";
import SolutionDetail from "@/views/solutionDetail.vue";
import EnterpriseInfo from "@/views/enterpriseInfo.vue";
import EnterpriseInfoDetail from "@/views/enterpriseInfoDetail.vue";
import About from "@/views/about.vue";
import ResumeDelivery from "@/views/resumeDelivery.vue";
import Service from "../views/service.vue";
import ServiceDetails from "../views/serviceDetails.vue";

Vue.use(VueRouter);

const routes = [{
  path: "/",
  name: "index",
  redirect: "/home",
  component: () => import("@/layouts/index"),
  children: [{
      path: "/home",
      name: "home",
      component: Home,
      meta: {
        label: "首页",
      },
    },
    {
      path: "/groupProfile",
      name: "groupProfile",
      component: GroupProfile,
      meta: {
        label: "集团简介",
      },
    },
    {
      path: "/service",
      name: "service",
      component: Service,
      meta: {
        label: "产品应用",
      },
    },
    {
      path: "/serviceDetails",
      name: "serviceDetails",
      component: ServiceDetails,
      meta: {
        label: "产品详情",
        hidden: true,
      },
    },
    {
      path: "/solution",
      name: "solution",
      component: Solution,
      meta: {
        label: "解决方案",
      },
    },
    {
      path: "/solutionDetail",
      name: "solutionDetail",
      component: SolutionDetail,
      meta: {
        label: "解决方案详情",
        hidden: true,
      },
    },
    {
      path: "/enterpriseInfo",
      name: "enterpriseInfo",
      component: EnterpriseInfo,
      meta: {
        label: "企业资讯",
      },
    },
    {
      path: "/enterpriseInfoDetail",
      name: "enterpriseInfoDetail",
      component: EnterpriseInfoDetail,
      meta: {
        label: "企业资讯详情",
        hidden: true,
      },
    },
    {
      path: "/about",
      name: "about",
      component: About,
      meta: {
        label: "关于我们",
      },
    },
    {
      path: "/resumeDelivery",
      name: "resumeDelivery",
      component: ResumeDelivery,
      meta: {
        label: "加入我们",
        hidden: true,
      },
    },
  ],
}, ];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (from.query.isLogin == "1") {
    let str = `${to.path}?isLogin=${from.query.isLogin}`
    for (let k in to.query) {
      str += `&${k}=${to.query[k]}`
    }
    window.location.href = str
  } else {
    next();
  }
  scrollTo(0, 0);
});

export default router;