<template>
  <div class="m_enterpriseInfo">
    <div class="meau_list">
      <MobileHead></MobileHead>
    </div>
    <!-- 背景图 -->
    <div class="group_bgc">
      <img src="../assets/image/3.png" />
      <div class="qiye_txt">企业资讯·行业新闻</div>
      <div class="qiye_eng">Enterprise Information and Industry News</div>
    </div>
    <!-- 导航列表 -->
    <div class="nav_list">
      <ul>
        <li
          v-for="(item, index) in navList"
          :key="index"
          :class="[index == navNum ? 'add_border' : '']"
          @touchend="changeNav(item, index)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <!-- 数据列表 -->
    <div class="data_lists">
      <div
        class="data_box"
        v-for="(item, index) in realTimeInfoList"
        :key="index"
        @click="goDetiail(item)"
      >
        <div class="img_box">
          <el-image :src="item.imgUrl" fit="cover"></el-image>
        </div>
        <div class="txt">
          <div class="title">{{ item.title }}</div>
          <div class="content">{{ item.content }}</div>
          <div class="date">{{ item.publishDate }}</div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <MobileFoot></MobileFoot>
  </div>
</template>

<script>
import MobileHead from "./mobileHead.vue";
import MobileFoot from "./mFoot.vue";
import { Enters, news } from "../api/modules/request.js";
import { baseURL } from "../api/baseUrl.js";
export default {
  components: {
    MobileHead,
    MobileFoot,
  },
  data() {
    return {
      navNum: 0,
      navList: ["企业动态", "行业新闻"],
      realTimeInfoList: null,
    };
  },
  watch: {
    $route(to, from) {
      this.navNum = Number(this.$route.query.type);
    },
  },
  mounted() {
    if (!this.$route.query.type) {
      this.navNum = 0;
      this.getEnter();
    } else {
      this.navNum = Number(this.$route.query.type);
      if (this.navNum === 0) {
        this.getEnter();
      } else {
        this.getnews();
      }
    }
  },
  methods: {
    // 点击列表菜单
    changeNav(val, index) {
      this.navNum = index;
      if (index === 0) {
        this.getEnter();
      } else {
        this.getnews();
      }
    },

    // 获取企业动态
    getEnter() {
      Enters().then((res) => {
        res.data.forEach((e) => {
          e.imgUrl = baseURL + e.imgUrl.substring(1);
        });
        this.realTimeInfoList = res.data;
      });
    },

    // 获取行业新闻
    getnews() {
      news().then((res) => {
        res.data.forEach((e) => {
          e.imgUrl = baseURL + e.imgUrl.substring(1);
        });
        this.realTimeInfoList = res.data;
      });
    },

    // 点击数据列表跳转到详情
    goDetiail(val) {
      this.$router.push({
        path: "/enterpriseInfoDetail",
        query: {
          id: val.id,
          type: this.navNum,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.m_enterpriseInfo {
  width: 100%;

  .meau_list {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5;
  }

  .group_bgc {
    width: 100%;
    height: 80rem;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .qiye_txt,
    .qiye_eng {
      position: absolute;
      color: #fff;
      font-size: 5.5rem;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-family: pf_cg;
    }

    .qiye_eng {
      width: 100%;
      text-align: center;
      top: 60%;
      font-size: 4.5rem;
    }
  }

  .nav_list {
    width: 100%;
    padding: 0 3%;
    box-sizing: border-box;

    ul {
      width: 100%;
      display: flex;
      border-bottom: 0.5rem solid #e9e9e9;

      li {
        padding: 3.5rem 0;
        box-sizing: border-box;
        font-size: 4.2rem;
        font-family: PingFang Medium;
        color: #333;
        width: 18%;
        text-align: center;
        margin-right: 2.5%;
      }

      .add_border {
        border-bottom: 0.8rem solid #0052d9;
      }
    }
  }

  .data_lists {
    width: 100%;
    margin-top: 4rem;
    padding: 0 3%;
    box-sizing: border-box;

    .data_box {
      width: 100%;
      height: 40rem;
      margin-bottom: 4rem;
      display: flex;

      .img_box {
        width: 35%;
        height: 40rem;

        .el-img {
          width: 100%;
          height: 100%;
        }
      }

      .txt {
        width: 63%;
        height: 40rem;
        position: relative;
        margin-left: 2%;

        .title {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 4.2rem;
          font-family: pf_cg;
          color: #333;
        }

        .content {
          width: 100%;
          height: 24rem;
          color: #666666;
          font-size: 3.4rem;
          font-family: pf_cg;
          overflow: auto;
        }

        .date {
          font-size: 3.4rem;
          color: #999999;
          font-family: pf_cg;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}
</style>
