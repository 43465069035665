import axios from 'axios'
import {
  get
} from 'lodash'
import {
  baseURL
} from './baseUrl'
import router from '@/router'
// import {
//   Loading
// } from 'element-ui';
// var loading = null;
axios.defaults.withCredentials = true; //*********配置每次请求默认携带cookie*****禁止删除****//
axios.defaults.crossDomain = true;

/**
 * @description 创建请求实例
 */
function createService() {
  // 创建一个 axios 实例
  const service = axios.create()
  // 请求拦截
  service.interceptors.request.use(
    config => config,
    error => {
      // 发送失败
      return Promise.reject(error)
    }
  )
  // 响应拦截
  service.interceptors.response.use(
    response => {
      // dataAxios 是 axios 返回数据中的 data
      const dataAxios = response.data
      // 这个状态码是和后端约定的

      // loading.close();
      const {
        code
      } = dataAxios
      // 根据 code 进行判断
      if (code === undefined) {
        // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
        return dataAxios
      } else {
        // 有 code 代表这是一个后端接口 可以进行进一步的判断
        switch (code) {
          case 200:
            // [ 示例 ] code === 200 代表没有错误
            return dataAxios
            //case '401':
            // [ 401 ] 没有权限,退出登录
            //break
          default:
            return dataAxios
            // 不是正确的 code
            break
        }
      }
    },
    error => {
      // loading.close();
      const status = get(error, 'response.status')
      switch (status) {
        case 400:
          error.message = '请求错误';
          break
        case 401:
          let msg = error.response.data.msg;
          if (msg) {
            error.message = msg;
            break
          } else {
            error.message = '未授权，请重新登录'
            break
          }
          case 403:
            error.message = '拒绝访问';
            break
          case 404:
            error.message = `请求地址出错: ${error.response.config.url}`;
            break
          case 405:
            error.message = '请求方式错误';
            break
          case 408:
            error.message = '请求超时';
            break
          case 500:
            error.message = '服务器内部错误';
            break
          case 501:
            error.message = '服务未实现';
            break
          case 502:
            error.message = '网关错误';
            break
          case 503:
            error.message = '服务不可用';
            break
          case 504:
            error.message = '网关超时';
            break
          case 505:
            error.message = 'HTTP版本不受支持';
            break
          default:
            break
      }
      if (status == 401) {
        router.push("/")
      }
      return Promise.reject(error)
    }
  )
  return service
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
function createRequestFunction(service) {
  return function (config) {
    const token = sessionStorage.getItem("token");
    const configDefault = {
      headers: {
        'Content-Type': get(config, 'headers.Content-Type', 'application/json')

      },
      timeout: 150000,
      baseURL: baseURL,
      data: {}
    }
    if (config.method === 'get' && window.location.search.indexOf('isLogin') !== -1) {
      if (!config.params) {
        config.params = {}
      }
      config.params.isLogin = 1

    }
    if (token != "null") {
      configDefault.headers.Authorization = "Bearer " + token
    }
    // loading = Loading.service({
    //   lock: true,
    //   text: "加载中……",
    //   background: "rgba(0, 0, 0, 0.7)",
    // });

    return service(Object.assign(configDefault, config))
  }
}

// 用于真实网络请求的实例和请求方法
export const service = createService()
export const request = createRequestFunction(service)

// 用于模拟网络请求的实例和请求方法
export const serviceForMock = createService()
export const requestForMock = createRequestFunction(serviceForMock)

// 网络请求数据模拟工具
// export const mock = new Adapter(serviceForMock)