<template>
  <div class="about">
    <div v-if="screenWidth > 640" style="width: 100%">
      <!-- banner -->
      <div class="banner">
        <img v-if="liChange == 0" src="../assets/image/about_us.jpg" alt="" />
        <img
          v-else-if="liChange == 1"
          src="../assets/image/bannerT.png"
          alt=""
        />
      </div>
      <!-- 关于我们列表 -->
      <div class="aboutUsBox">
        <ul>
          <li
            v-for="(item, index) in aboutUsBoxList"
            :key="index"
            :class="liChange == index ? 'liChange' : ''"
            @click="changeLi(item, index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <!-- 集团简介 -->
      <div class="introduction" v-show="liChange == 0">
        <div class="backgroundPic"></div>
      </div>
      <!-- 加入我们 -->
      <div class="join" v-show="liChange == 0">
        <el-collapse accordion>
          <el-collapse-item
            :title="item.jobName"
            :name="index"
            v-for="(item, index) in joinUsList"
            :key="index"
          >
            <div class="jobRes">
              <div class="deliverResume" @click="delivery(item)">立即投递</div>
              <div class="title">
                <div class="line"></div>
                <div class="p">岗位职责</div>
              </div>
              <div class="text" v-html="item.jobDescription"></div>
            </div>
            <div class="jobReq">
              <div class="title">
                <div class="line"></div>
                <div class="p">任职要求</div>
              </div>
              <div class="text" v-html="item.requirements"></div>
            </div>
            <div class="salary">
              <div class="title">
                <div class="line"></div>
                <div class="p">薪资待遇</div>
              </div>
              <div class="text" style="font-family: pf_cg">
                {{ item.salary }}
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <!-- 联系我们 -->
      <div class="contacts" v-show="liChange == 1">
        <el-tabs
          v-model="activeName"
          type="card"
          @tab-click="handleClick"
          style="padding: 0"
        >
          <el-tab-pane
            :label="item.companyName"
            :name="item.companyName"
            v-for="(item, index) in contactList"
            :key="index"
            style="color: #666666"
          >
            <div id="map">
              <baidu-map
                v-if="mapShow"
                :center="{ lng: item.lon, lat: item.lat }"
                :zoom="15"
                style="height: 460px; width: 100%"
                :scroll-wheel-zoom="true"
              >
                <bm-marker
                  :position="{ lng: item.lon, lat: item.lat }"
                ></bm-marker>
                <bm-label
                  :position="{ lng: item.lon, lat: item.lat }"
                  :content="item.companyName"
                  :labelStyle="{
                    color: 'blue',
                    borderRadius: '10px',
                    borderColor: '#ccc',
                    padding: '10px',
                    fontSize: '16px',
                    height: '30px',
                    lineHeight: '30px',
                  }"
                >
                </bm-label>
              </baidu-map>
            </div>
            <div class="contactInfor">
              <div class="line"></div>
              <div class="boxs">
                <div class="Bcontacts">
                  <span v-show="item.contacts != ''">联系人：</span
                  >{{ item.contacts }}
                </div>
                <div class="Baddress">
                  <span v-show="item.location != ''">地 &nbsp;&nbsp;址：</span
                  >{{ item.location }}
                </div>
                <div class="Bemail">
                  <span v-show="item.mail != ''">邮 &nbsp;&nbsp;箱：</span
                  >{{ item.mail }}
                </div>
                <div class="Bphone">
                  <span v-show="item.tel != ''">联系电话：</span>{{ item.tel }}
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div v-else-if="screenWidth <= 640" style="width: 100%">
      <mAbout></mAbout>
    </div>
  </div>
</template>

<script>
import { recruitmentInfo, enterprise } from "../api/modules/request.js";
import mAbout from "../components/mAbout.vue";
export default {
  data() {
    return {
      aboutUsBoxList: ["加入我们", "联系我们"],
      liChange: 0,
      joinUsList: null,
      activeName: null,
      contactList: null,
      mapShow: false,
      screenWidth: null,
    };
  },
  components: {
    mAbout,
  },
  created() {
    this.screenWidth = document.documentElement.clientWidth;
    if (this.$route.query.id == undefined) {
      this.liChange = 0;
      this.getRecruitmentInfo();
      this.getenterprise();
    } else {
      this.liChange = this.$route.query.id;
      this.getRecruitmentInfo();
      this.getenterprise();
    }
  },
  mounted() {},
  methods: {
    // 获取所有集团公司
    getenterprise() {
      enterprise().then((res) => {
        this.contactList = res.data;
        this.activeName = this.contactList[0].companyName;
      });
    },

    // 点击关于我们列表
    changeLi(val, index) {
      this.liChange = index;
      this.mapShow = false;
      this.$nextTick(function() {
        this.mapShow = true;
      });
    },

    // 获取所有的招聘信息
    getRecruitmentInfo() {
      recruitmentInfo().then((res) => {
        this.joinUsList = res.data;
      });
    },

    // 点击菜单
    handleClick(tab, event) {
      this.mapShow = false;
      this.$nextTick(function() {
        this.mapShow = true;
      });
    },

    // 投递简历
    delivery(val) {
      let obj = {
        id: val.id,
        jobName: val.jobName,
      };
      this.$router.push({
        path: "/resumeDelivery",
        query: {
          val: JSON.stringify(obj),
        },
      });
    },
  },
};
</script>

<style lang="less">
.about {
  width: 100%;
  position: relative;

  .banner {
    width: 100%;
    height: 450px;
    margin-top: 80px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .aboutUsBox {
    height: 70px;
    width: 100%;
    padding: 0 360px;
    box-sizing: border-box;
    border-bottom: 2px solid #e4e7ed;
    margin-bottom: 58px;
    position: relative;

    ul {
      width: 100%;
      display: flex;

      li {
        width: 140px;
        height: 70px;
        line-height: 70px;
        font-size: 1.25rem;
        margin-right: 72px;
        position: relative;
        transition: 0.2s all linear;
        cursor: pointer;
        text-align: center;
        color: #666666;
        font-family: pf_cg;
      }

      li::before {
        content: "";
        position: absolute;
        top: 0;
        left: 100%;
        width: 0;
        height: 100%;
        border-bottom: 4px solid #0052d9;
        transition: 0.2s all linear;
      }

      li:hover::before {
        width: 140px;
        top: 0;
        left: 0;
        transition-delay: 0.1s;
        border-bottom-color: #0052d9;
        z-index: -1;
      }

      li:hover ~ li::before {
        left: 0;
      }

      li:nth-child(6) {
        margin-right: 0;
      }

      .liChange {
        color: #333333;
        border-bottom: 4px solid #0052d9;
        font-family: PingFang Medium;
      }
    }
  }

  .introduction {
    padding: 0 360px;
    box-sizing: border-box;
    width: 100%;
    position: relative;

    .backgroundPic {
      width: 100%;
      height: 250px;
      background: url(../assets/image/gsbj.png) no-repeat;
      background-size: 100% 100%;
      position: relative;
      margin-bottom: 50px;
    }
  }

  .join {
    width: 100%;
    padding: 0 360px;
    box-sizing: border-box;
    margin-bottom: 100px;

    .el-collapse-item__header {
      position: relative;
      padding-left: 20px;
      box-sizing: border-box;
    }

    .el-collapse-item__header:hover {
      background-color: #eeeeee;
    }

    .jobRes,
    .jobReq,
    .salary {
      width: 100%;
      margin-bottom: 70px;
      position: relative;

      .deliverResume {
        position: absolute;
        width: 140px;
        height: 35px;
        background-color: #0052d9;
        color: #fff;
        font-size: 1rem;
        text-align: center;
        line-height: 35px;
        position: absolute;
        right: 10px;
        font-family: PingFang Medium;
        cursor: pointer;
      }

      .title {
        display: flex;
        width: 100%;
        margin: 10px 0 20px 0;

        .line {
          width: 6px;
          height: 25px;
          background-color: #0052d9;
          margin-right: 5px;
        }

        .p {
          height: 25px;
          line-height: 25px;
          font-size: 1.125rem;
          color: #333333;
          font-family: PingFang Medium;
        }
      }

      .text {
        color: #666666;
        font-size: 1rem;
        font-family: pf_cg;
      }
    }

    .salary {
      margin-bottom: 0;
    }
  }

  .contacts {
    width: 1200px;
    margin: 0 auto;

    #map {
      width: 1200px;
      margin-bottom: 30px;
      position: relative;
    }

    .contactInfor {
      margin-bottom: 100px;
      display: flex;

      .line {
        width: 5px;
        height: 35px;
        margin-right: 10px;
        background-color: #0052d9;
      }

      .boxs {
        .Bcontacts,
        .Baddress,
        .Bemail,
        .Bphone {
          color: #666666;
          font-size: 1.1rem;
          font-family: PingFang Medium;
          line-height: 35px;
        }
      }
    }
  }
}
</style>
