<template>
  <div class="home_box">
    <div class="meau_list">
      <MobileHead></MobileHead>
    </div>
    <!-- 轮播图 -->
    <div class="rotation_box">
      <mt-swipe :auto="4000">
        <mt-swipe-item v-for="(item, index) in rotationList" :key="index">
          <img :src="item.imgUrl" />
        </mt-swipe-item>
      </mt-swipe>
    </div>
    <!-- 公司宗旨 -->
    <div class="purpose">
      <div>
        <el-image :src="purposeList[0].src" fit="fill"></el-image>
      </div>
      <div>
        <el-image :src="purposeList[1].src" fit="fill"></el-image>
      </div>
      <div>
        <el-image :src="purposeList[2].src" fit="fill"></el-image>
      </div>
    </div>
    <!-- 公司经营范围 -->
    <div class="management">
      <div class="range">
        <mt-swipe :auto="4000">
          <mt-swipe-item v-for="(item, index) in rangeList" :key="index">
            <div class="rang_box">
              <div class="img_box">
                <img :src="item.imgUrl" />
                <div class="title">{{ item.title }}</div>
              </div>
              <div class="range_list">
                <div v-html="item.content"></div>
              </div>
            </div>
          </mt-swipe-item>
        </mt-swipe>
      </div>
    </div>
    <!-- 解决方案模块展示 -->
    <div class="modular">
      <mt-swipe :auto="4000">
        <mt-swipe-item v-for="(item, index) in modularList" :key="index">
          <div class="li_modular" @click="solutionBtn(item)">
            <div class="title_box">
              <div class="line"></div>
              <div class="name">{{ item.name }}</div>
            </div>
            <div class="modular_content">{{ item.content }}</div>
            <div class="image_box">
              <img :src="item.imgUrl" />
            </div>
          </div>
        </mt-swipe-item>
      </mt-swipe>
    </div>
    <!-- 产品展示 -->
    <div class="product_box">
      <div class="product_title">产品展示</div>
      <div class="equipment">
        <mt-swipe :auto="4000" :showIndicators="false">
          <mt-swipe-item v-for="(item, index) in equipmentList" :key="index">
            <div class="li_equipment">
              <div class="img_title">
                <div class="title">{{ item.name }}</div>
                <div class="line"></div>
                <div class="img_box">
                  <el-image :src="item.imgUrl" fit="scale-down"></el-image>
                  <!-- <img :src="item.imgUrl" /> -->
                </div>
              </div>
              <div class="li_txt">
                <div class="li_consent" v-html="item.content"></div>
              </div>
            </div>
          </mt-swipe-item>
        </mt-swipe>
      </div>
    </div>
    <!-- 行业新闻 -->
    <div class="industry_news">
      <mt-swipe :auto="4000" :showIndicators="false">
        <mt-swipe-item v-for="(item, index) in industryList" :key="index">
          <div class="new_txts" @click="arrowClick(item.id)">
            <img :src="item.homePageImgUrl" />
            <div class="txts" @touchend="moreBtn(item.id)">
              <div class="title">{{ item.title }}</div>
              <div class="content">{{ item.content }}</div>
              <div class="more">更多>></div>
            </div>
          </div>
        </mt-swipe-item>
      </mt-swipe>
    </div>
    <!-- 合作伙伴 -->
    <div class="cooperative_partner">
      <div class="partner_title">合作伙伴</div>
      <div class="partner_box">
        <div class="pb_list" v-for="(item, index) in partnerList" :key="index">
          <img :src="item.logoImgUrl" />
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <MobileFoot></MobileFoot>
  </div>
</template>

<script>
import MobileHead from "./mobileHead.vue";
import MobileFoot from "./mFoot.vue";
import { baseURL } from "../api/baseUrl.js";
import {
  gotationImg,
  threeDimensional,
  modelBriefIn,
  product,
  partner,
  Enters,
} from "../api/modules/request";
export default {
  components: {
    MobileHead,
    MobileFoot,
  },
  data() {
    return {
      rotationList: null,
      rangeList: null,
      modularList: null,
      equipmentList: null,
      industryList: null,
      partnerList: null,
      purposeList: [
        {
          src: require("../assets/image/zxsn.png"),
        },
        {
          src: require("../assets/image/kfcx.png"),
        },
        {
          src: require("../assets/image/jzfw.png"),
        },
      ],
    };
  },
  mounted() {
    this.getRotationChartm();
    this.getThreeData();
    this.getSolutionData();
    this.getProduct();
    this.getCorporateNews();
    this.getPartnerData();
  },
  methods: {
    moreBtn(val) {
      console.log(val);
    },

    // 轮播图
    getRotationChartm() {
      gotationImg().then((res) => {
        res.data.forEach((item) => {
          item.imgUrl = baseURL + item.imgUrl.substring(1);
        });
        this.rotationList = res.data;
      });
    },

    // 公司经营范围
    getThreeData() {
      threeDimensional().then((res) => {
        res.data.forEach((e) => {
          e.imgUrl = baseURL + e.imgUrl.substring(1);
        });
        this.rangeList = res.data;
      });
    },

    // 解决方案模块展示
    getSolutionData() {
      modelBriefIn().then((res) => {
        res.data.forEach((e) => {
          e.imgUrl = baseURL + e.imgUrl.substring(1);
        });
        this.modularList = res.data;
      });
    },

    // 跳转到解决方案详情
    solutionBtn(val) {
      this.$router.push({
        path: "/solutionDetail",
        query: {
          id: val.id,
          type: val.digitalTwinsId - 1,
        },
      });
    },

    // 获取产品列表
    getProduct() {
      product().then((res) => {
        res.data.forEach((e) => {
          e.imgUrl = baseURL + e.imgUrl.substring(1);
        });
        this.equipmentList = res.data;
      });
    },

    // 行业新闻
    getCorporateNews() {
      Enters().then((res) => {
        var newArr = [];
        res.data.forEach((item) => {
          if (item.isHomePage == 1) {
            newArr.push(item);
          }
        });
        newArr.forEach((e) => {
          e.homePageImgUrl = baseURL + e.homePageImgUrl.substring(1);
        });
        this.industryList = newArr;
      });
    },

    // 跳转到行业新闻详情
    arrowClick(val) {
      this.$router.push({
        path: "/enterpriseInfoDetail",
        query: {
          id: val,
          type: 0,
        },
      });
    },

    // 获取合作伙伴
    getPartnerData() {
      partner().then((res) => {
        res.data.forEach((e) => {
          e.logoImgUrl = baseURL + e.logoImgUrl.substring(1);
        });
        this.partnerList = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home_box {
  width: 100%;

  .meau_list {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5;
  }

  .rotation_box {
    width: 100%;
    height: 95rem;
    overflow: hidden;

    img {
      width: 100%;
      max-height: 100%;
    }
  }

  .purpose {
    width: 100%;
    height: 35rem;
    margin-top: 6rem;
    display: flex;
    justify-content: space-between;
    padding: 0 2.5%;
    box-sizing: border-box;

    div {
      width: 32%;
      height: 35rem;

      img {
        width: 100%;
        height: 100%;
      }

      .el-image {
        width: 100%;
        height: 100%;
      }
    }
  }

  .management {
    width: 100%;
    padding: 0 2.5%;
    box-sizing: border-box;

    .range {
      width: 100%;
      height: 60rem;
      background-color: rgb(255, 255, 255);
      border-radius: 2rem;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);

      .rang_box {
        width: 100%;
        height: 100%;
        display: flex;

        .img_box {
          width: 50%;
          height: 60rem;

          img {
            width: 55%;
            max-height: 30rem;
            margin-left: 22.5%;
            margin-top: 12rem;
          }

          .title {
            color: #0052d9;
            font-size: 4.2rem;
            font-family: PingFang Medium;
            text-align: center;
          }
        }
        .range_list {
          width: 50%;
          height: 60rem;
          position: relative;

          div {
            position: absolute;
            width: 100%;
            line-height: 8.5rem;
            font-size: 4rem;
            color: #666;
            font-family: pf_cg;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  .modular {
    width: 100%;
    height: 132rem;
    background: url("../assets/image/product_bgc.png");
    margin-top: 8rem;
    padding: 8rem 0;
    box-sizing: border-box;

    .li_modular {
      width: 100%;
      height: 100%;
      color: #fff;
      padding: 0 2.5%;
      box-sizing: border-box;

      .title_box {
        width: 100%;
        display: flex;

        .line {
          width: 1.5rem;
          height: 6.5rem;
          background-color: #0052d9;
          margin-right: 2rem;
        }

        .name {
          font-size: 5rem;
          line-height: 6.5rem;
          font-family: PingFang Medium;
        }
      }

      .modular_content {
        margin-top: 4rem;
        height: 34rem;
        font-family: pf_cg;
        font-size: 4.2rem;
        line-height: 7rem;
        text-indent: 8.4rem;
        overflow: auto;
        padding: 0 2%;
        box-sizing: border-box;
      }

      .image_box {
        width: 100%;
        height: 67rem;
        margin-top: 4rem;
        background-color: #fff;

        img {
          width: 100%;
          height: 100%;
          border-radius: 2rem;
        }
      }
    }
  }

  .product_box {
    width: 100%;
    height: 73rem;
    margin-top: 8rem;

    .product_title {
      color: #333;
      font-family: YouSheBiaoTiHei-2;
      width: 100%;
      text-align: center;
      font-size: 8rem;
    }

    .equipment {
      width: 100%;
      height: 65rem;
      padding: 0 2.5%;
      box-sizing: border-box;

      .li_equipment {
        width: 100%;
        height: 65rem;
        display: flex;
        padding-top: 2rem;
        box-sizing: border-box;

        .img_title,
        .li_txt {
          width: 50%;
          height: 65rem;

          .title {
            color: #333;
            font-size: 4rem;
            margin-left: 2rem;
            font-family: PingFang Medium;
            font-weight: 700;
          }

          .line {
            width: 6rem;
            height: 1rem;
            background-color: #0052d9;
            border-radius: 1rem;
            margin: 0 0 0 2rem;
          }

          .img_box {
            width: 65%;
            height: 35rem;
            background-color: #fff;
            margin: 10rem 0 0 17.5%;

            .el-image {
              width: 100%;
              height: 100%;
            }
          }

          .li_consent {
            color: #666;
            font-family: pf_cg;
            font-size: 4rem;
            line-height: 6.1rem;
          }
        }

        .li_txt {
          padding: 4rem 0 0 0;
          box-sizing: border-box;
          overflow: auto;
        }
      }
    }
  }

  .industry_news {
    width: 100%;
    height: 120rem;
    margin-top: 8rem;

    .new_txts {
      width: 100%;
      height: 120rem;
      color: #fff;

      img {
        width: 100%;
        height: 67rem;
      }

      .txts {
        width: 100%;
        height: 53rem;
        padding: 4rem 2.5%;
        box-sizing: border-box;
        background-color: #0052d9;

        .title {
          font-family: YouSheBiaoTiHei-2;
          font-size: 6.2rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .content {
          font-size: 4rem;
          font-family: pf_cg;
          height: 22rem;
          overflow: auto;
        }

        .more {
          font-size: 4.2rem;
          font-family: pf_cg;
          margin-top: 2rem;
          text-align: right;
          margin-right: 2rem;
        }
      }
    }
  }

  .cooperative_partner {
    width: 100%;
    margin: 8rem 0;

    .partner_title {
      text-align: center;
      font-size: 8rem;
      font-family: YouSheBiaoTiHei-2;
      margin-bottom: 4rem;
    }

    .partner_box {
      width: 100%;
      padding: 0 2.5%;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .pb_list {
        width: 32%;
        height: 22rem;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        margin-bottom: 30px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
