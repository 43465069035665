<template>
  <div class="footer_box">
    <el-collapse @change="handleChange">
      <el-collapse-item
        v-for="(item, index) in footerList"
        :key="index"
        :title="item.title"
      >
        <ul>
          <li
            v-for="(i, index) in item.list"
            :key="index"
            @click="AlinkClick(i)"
          >
            {{ i }}
          </li>
        </ul>
      </el-collapse-item>
    </el-collapse>
    <div class="keep_record">
      ©2019 星闪世图科技集团&nbsp;&nbsp;&nbsp;&nbsp;备案号:京ICP备11030972号
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      footerList: [
        {
          title: "关于我们",
          list: ["集团简介", "发展历程", "行业奖项"],
        },
        {
          title: "企业资讯",
          list: ["企业动态", "行业新闻"],
        },
        {
          title: "产品服务",
          list: ["无人机", "巡线机器人", "AI盒子", "卫星高精度定位终端"],
        },
        {
          title: "加入我们",
          list: ["人才招聘"],
        },
        {
          title: "解决方案",
          list: ["智慧能源", "智慧交通", "智慧水务", "智慧文旅", "数字乡村"],
        },
      ],
    };
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },

    // 菜单
    AlinkClick(val) {
      if (val == "集团简介") {
        window.location.href = "/groupProfile#briefInt";
      } else if (val == "发展历程") {
        window.location.href = "/groupProfile#briefInt";
      } else if (val == "行业奖项") {
        window.location.href = "/groupProfile#prize";
      } else if (val == "企业动态") {
        this.$router.push({
          path: "enterpriseInfo",
          query: {
            type: 0,
          },
        });
      } else if (val == "行业新闻") {
        this.$router.push({
          path: "enterpriseInfo",
          query: {
            type: 1,
          },
        });
      } else if (val == "人才招聘") {
        this.$router.push({
          path: "About",
          query: {
            id: 0,
          },
        });
      } else if (val == "智慧能源") {
        this.$router.push({
          path: "solution",
          query: {
            type: 0,
          },
        });
      } else if (val == "智慧交通") {
        this.$router.push({
          path: "solution",
          query: {
            type: 1,
          },
        });
      } else if (val == "智慧水务") {
        this.$router.push({
          path: "solution",
          query: {
            type: 2,
          },
        });
      } else if (val == "智慧文旅") {
        this.$router.push({
          path: "solution",
          query: {
            type: 3,
          },
        });
      } else if (val == "数字乡村") {
        this.$router.push({
          path: "solution",
          query: {
            type: 4,
          },
        });
      } else if (val == "无人机") {
        window.location.href = "/service";
      } else if (val == "巡线机器人") {
        window.location.href = "/service";
      } else if (val == "AI盒子") {
        window.location.href = "/service";
      } else if (val == "卫星高精度定位终端") {
        window.location.href = "/service";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.footer_box {
  width: 100%;
  background-color: #f6f6f6;
  padding: 6rem 5% 15rem;
  box-sizing: border-box;
  padding-bottom: 8rem;

  li {
    font-size: 4rem;
    margin-left: 4rem;
    line-height: 10rem;
    font-family: pf_cg;
  }

  .keep_record {
    width: 100%;
    margin-top: 6rem;
    font-size: 3rem;
    text-align: center;
    font-family: pf_cg;
  }
}
</style>
