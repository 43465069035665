<template>
  <div class="platform">
    <div v-if="screenWidth > 640" style="width: 100%">
      <div class="banner">
        <img src="@/assets/image/jufa.png" />
      </div>
      <!-- 平台列表 -->
      <div class="platformClass">
        <ul>
          <li
            v-for="(item, index) in platformList"
            :key="index"
            :class="liChange == index ? 'liChange' : ''"
            @click="changeLi(item, index)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <!-- tab container -->
      <div class="transmission">
        <div class="backgroundPic">
          <img :src="platformList ? platformList[liChange].imgUrl : ''" />
          <div class="paln_txt">
            <div class="plan_name">{{ platformList[liChange].name }}</div>
            <div class="plan_line"></div>
            <div class="paln_content">{{ platformList[liChange].content }}</div>
          </div>
        </div>
        <div class="list">
          <div
            v-for="(item, index) in transmissionList"
            :key="index"
            :class="[
              index % 2 == 0 ? 'containerOne' : 'containerTwo',
              containerIndex == index
                ? 'containerOneEnter'
                : 'containerOneLeave',
            ]"
            @mouseenter="containerEnter(index)"
            @mouseleave="containerLeave()"
            @click="platDetails(item)"
          >
            <div class="left">
              <div class="top">
                <span></span>
                <div class="title">{{ item.name }}</div>
              </div>
              <div class="text">{{ item.content }}</div>
            </div>
            <div class="right">
              <img :src="item.imgUrl" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="screenWidth <= 640" style="width: 100%">
      <msolu></msolu>
    </div>
  </div>
</template>

<script>
import { industryModel, modelBriefIn } from "../api/modules/request.js";
import { baseURL } from "../api/baseUrl.js";
import msolu from "../components/mSolu.vue";
export default {
  components: {
    msolu,
  },
  data() {
    return {
      platformList: null,
      liChange: 0,
      transmissionList: null,
      firstId: null,
      screenWidth: null,
      containerIndex: null,
    };
  },
  created() {
    this.screenWidth = document.documentElement.clientWidth;
    if (!this.$route.query.type) {
      this.liChange = 0;
      industryModel().then((res) => {
        this.platformList = res.data;
        this.platformList.forEach((item) => {
          item.imgUrl = baseURL + item.imgUrl.substring(1);
        });
        this.firstId = this.platformList[0].id;
        modelBriefIn({
          digitalTwinsId: this.firstId,
        }).then((res) => {
          this.transmissionList = res.data;
          this.transmissionList.forEach((item) => {
            item.imgUrl = baseURL + item.imgUrl.substring(1);
          });
        });
      });
    } else {
      this.liChange = Number(this.$route.query.type);
      industryModel().then((res) => {
        this.platformList = res.data;
        this.platformList.forEach((item) => {
          item.imgUrl = baseURL + item.imgUrl.substring(1);
        });
        modelBriefIn({
          digitalTwinsId: this.$route.query.digitalTwinsId,
        }).then((res) => {
          this.transmissionList = res.data;
          this.transmissionList.forEach((item) => {
            item.imgUrl = baseURL + item.imgUrl.substring(1);
          });
        });
      });
    }
  },
  watch: {
    $route(to, from) {
      this.liChange = Number(this.$route.query.type);
      this.changeLi({ id: this.$route.query.digitalTwinsId }, this.liChange);
    },
  },
  mounted() {},
  methods: {
    // 点击产品列表
    changeLi(val, index) {
      modelBriefIn({
        digitalTwinsId: val.id,
      }).then((res) => {
        this.liChange = index;
        this.transmissionList = res.data;
        this.transmissionList.forEach((item) => {
          item.imgUrl = baseURL + item.imgUrl.substring(1);
        });
      });
    },

    // 鼠标进入列表
    containerEnter(index) {
      this.containerIndex = index;
    },

    // 鼠标离开
    containerLeave() {
      this.containerIndex = null;
    },

    // 详情
    platDetails(val) {
      this.$router.push({
        name: "solutionDetail",
        query: {
          id: val.id,
          type: this.liChange,
        },
      });
    },
  },
};
</script>

<style lang="less">
.platform {
  width: 100%;
  position: relative;

  .banner {
    width: 100%;
    height: 450px;
    margin-top: 80px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .platformClass {
    height: 70px;
    width: 100%;
    padding: 0 360px;
    box-sizing: border-box;
    border-bottom: 2px solid #e4e7ed;
    margin-bottom: 58px;
    position: relative;

    ul {
      width: 100%;
      display: flex;

      li {
        width: 140px;
        height: 70px;
        line-height: 70px;
        font-size: 1.25rem;
        margin-right: 125px;
        position: relative;
        transition: 0.2s all linear;
        cursor: pointer;
        text-align: center;
        color: #666666;
        font-family: pf_cg;
      }

      li::before {
        content: "";
        position: absolute;
        top: 0;
        left: 100%;
        width: 0;
        height: 100%;
        border-bottom: 4px solid #0052d9;
        transition: 0.2s all linear;
      }

      li:hover::before {
        width: 140px;
        top: 0;
        left: 0;
        transition-delay: 0.1s;
        border-bottom-color: #0052d9;
        z-index: -1;
      }

      li:hover ~ li::before {
        left: 0;
      }

      li:nth-child(5) {
        margin-right: 0;
      }

      .liChange {
        color: #333333;
        border-bottom: 4px solid #0052d9;
        font-family: PingFang Medium;
      }
    }
  }

  .transmission,
  .highway,
  .waterAffairs,
  .culturalTourism,
  .rural,
  .agriculture {
    padding: 0 360px;
    box-sizing: border-box;
    width: 100%;
    position: relative;

    .backgroundPic {
      width: 100%;
      height: 250px;
      position: relative;
      margin-bottom: 70px;

      img {
        width: 100%;
        height: 100%;
      }

      .paln_txt {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0 50px;
        box-sizing: border-box;
        font-family: pf_cg;
        color: #fff;

        .plan_name {
          margin-top: 50px;
          font-size: 36px;
        }

        .plan_line {
          width: 72px;
          height: 4px;
          border-radius: 4px;
          background-color: #fff;
          margin-bottom: 20px;
        }

        .paln_content {
          font-size: 21px;
        }
      }
    }

    .list {
      width: 100%;
      cursor: pointer;

      .containerOne {
        width: 100%;
        height: 338px;
        display: flex;
        margin-bottom: 70px;

        .left {
          width: 50%;
          height: 100%;

          .top {
            display: flex;

            span {
              display: inline-block;
              width: 8px;
              height: 50px;
              background-color: #0052d9;
            }

            .title {
              color: #333333;
              font-size: 1.5rem;
              height: 50px;
              line-height: 50px;
              margin-left: 10px;
              margin-bottom: 30px;
              font-family: pf_cg;
            }
          }

          .text {
            height: 258px;
            width: 570px;
            overflow: auto;
            color: #666666;
            font-size: 1.1rem;
            text-indent: 36px;
            line-height: 35px;
            font-family: pf_cg;
          }
        }

        .right {
          width: 50%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .containerOneEnter {
        animation: scaleDraw 0.3s forwards ease-in-out;
      }

      @keyframes scaleDraw {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(1.03);
        }
      }

      .containerOneLeave {
        animation: scaleDrawLeave 0.3s forwards ease-in-out;
      }

      @keyframes scaleDrawLeave {
        0% {
          transform: scale(1.03);
        }
        100% {
          transform: scale(1);
        }
      }

      .containerTwo {
        width: 100%;
        height: 338px;
        display: flex;
        flex-flow: row-reverse;
        margin-bottom: 70px;

        .left {
          width: 50%;
          height: 100%;
          margin-left: 30px;

          .top {
            display: flex;

            span {
              display: inline-block;
              width: 8px;
              height: 50px;
              background-color: #0052d9;
            }

            .title {
              color: #333333;
              font-size: 1.5rem;
              height: 50px;
              line-height: 50px;
              margin-left: 10px;
              margin-bottom: 30px;
              font-family: pf_cg;
            }
          }

          .text {
            height: 258px;
            width: 570px;
            overflow: auto;
            color: #666666;
            font-size: 1.1rem;
            text-indent: 36px;
            line-height: 35px;
            font-family: pf_cg;
          }
        }

        .right {
          width: 50%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
