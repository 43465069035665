let GropMapList = [{
        name: "无锡",
        title: "江苏星闪世图科技（集团）有限公司",
        address: "",
        Emai: "",
        phone: "",
    },
    {
        name: "北京",
        title: "北京星闪世图科技有限公司",
        address: "",
        Emai: "",
        phone: "",
    },
    {
        name: "西安",
        title: "西安星闪世图科技有限公司",
        address: "",
        Emai: "",
        phone: "",
    },
    {
        name: "杭州",
        title: "杭州星闪数创科技有限公司",
        address: "",
        Emai: "",
        phone: "",
    },
    {
        name: "福建",
        title: "福建星闪世图科技有限公司",
        address: "",
        Emai: "",
        phone: "",
    },
    {
        name: "新疆",
        title: "新疆星闪世图科技有限公司",
        address: "",
        Emai: "",
        phone: "",
    }
]

export {
    GropMapList
}