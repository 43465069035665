<template>
  <div class="mGroup_box">
    <div class="meau_list">
      <MobileHead></MobileHead>
    </div>
    <!-- 背景图 -->
    <div class="group_bgc">
      <img src="../assets/image/jtjjs.png" />
      <div class="jt_txt">星闪世图科技集团简介</div>
      <div class="jt_eng">Group Profile</div>
    </div>
    <!-- 公司简介 -->
    <a name="briefInt"></a>
    <div class="brief_introduction">
      <div
        class="briefInt_txt"
        v-for="(item, index) in briefIntList"
        :key="index"
      >
        {{ item.content }}
      </div>
    </div>
    <!-- 发展历程 -->
    <a name="briefInt"></a>
    <div class="course">
      <div class="particular_year">
        <div class="title">发展历程</div>
        <div class="year_list">
          <div class="swiper courseSwiper">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in companyMouYear"
                :key="index"
                @click="developChange(item, index)"
              >
                <div class="course_title">{{ item }}</div>
                <div class="course_line"></div>
                <div class="outer_circle"></div>
                <div
                  class="inner_circle"
                  :class="[developMoIndex == index ? 'change_inner' : '']"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 发展历程数据 -->
      <div class="develop_mobile">
        <div class="swiper devemobSwiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in monthEventMoList"
              :key="index"
            >
              <div class="date_mobile">{{ item.mouth }}</div>
              <div class="list_mobuile">
                <ul>
                  <li v-for="(i, index) in item.event" :key="index">{{ i }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 行业奖项 -->
    <a name="prize"></a>
    <div class="prize">
      <div class="title">行业奖项</div>
      <div class="prize_roll">
        <vue-seamless-scroll
          :data="prizeList"
          class="roll_content"
          :class-option="mobuileOption"
        >
          <div
            class="roll_list"
            v-for="(item, index) in prizeList"
            :key="index"
          >
            <div class="circle_box"></div>
            <div class="inner_ring"></div>
            <div class="list_txt">{{ item.content }}</div>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
    <!-- 荣耀证书 -->
    <div class="glory">
      <div class="glory_title">荣誉展示</div>
      <div class="honor_list">
        <div class="swiper-container glorySwiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in honorList"
              :key="index"
            >
              <div class="flower">
                <img src="../assets/image/Medal.png" />
              </div>
              <el-image
                :preview-src-list="[item.imgUrl]"
                :src="item.imgUrl"
                fit="contain"
              ></el-image>
              <div class="honor_txt">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <MobileFoot></MobileFoot>
  </div>
</template>

<script>
import BScroll from "@better-scroll/core";
import MobileHead from "./mobileHead.vue";
import Swiper from "swiper";
import "swiper/css/swiper.css";
import {
  groupIntroduction,
  history,
  prize,
  honor,
} from "../api/modules/request.js";
import { baseURL } from "../api/baseUrl.js";
import MobileFoot from "./mFoot.vue";
export default {
  components: {
    MobileHead,
    MobileFoot,
  },
  data() {
    return {
      briefIntList: null,
      prizeList: [],
      honorList: [],
      bs: null,
      honorSwiper: null,
      courseSwipers: null,
      deveSwiper: null,
      companyMouYear: null,
      yearEventMoList: null,
      monthEventMoList: [],
      developMoIndex: 0,
      animateUp: false,
    };
  },
  computed: {
    mobuileOption() {
      return {
        step: 0.6,
        limitMoveNum: this.prizeList.length,
        hoverStop: true,
        direction: 1,
        openWatch: true,
      };
    },
  },
  mounted() {
    // 荣耀展示
    honor().then((res) => {
      res.data.forEach((i) => {
        i.imgUrl = baseURL + i.imgUrl.substring(1);
      });
      this.honorList = res.data;
      this.$nextTick(() => {
        this.carousel();
      });
    });
    this.getBriefInt();
    this.getPrizeData();
    this.getYearsArr();
  },
  methods: {
    // 获取公司简介
    getBriefInt() {
      groupIntroduction().then((res) => {
        this.briefIntList = res.data;
      });
    },

    // 获取行业奖项数据
    getPrizeData() {
      prize().then((res) => {
        res.data.forEach((i) => {
          let newArr = i.awardDate.split("");
          newArr[4] = ".";
          i.content = newArr.join("") + " " + i.content;
        });
        this.prizeList = res.data;
      });
    },

    // 获取荣誉展示数据
    carousel() {
      this.honorSwiper = new Swiper(".glorySwiper", {
        slidesPerView: 2,
        slidesPerColumnFill: "row",
        slidesPerColumn: 2,
        spaceBetween: 5,
        autoplay: {
          disableOnInteraction: false,
          delay: 2000,
        },
      });
    },

    // 获取公司发展的总年份
    getYearsArr() {
      history().then((res) => {
        let historyList = [];
        res.data.map((data) => {
          for (let k in data) {
            historyList.push({
              date: k,
              list: data[k],
            });
          }
        });
        let arrYears = [];
        historyList.forEach((item) => {
          arrYears.push(item.date);
        });
        this.companyMouYear = arrYears.reverse();
        this.yearEventMoList = historyList;
        let mouthArr = [];
        mouthArr.push(this.yearEventMoList[this.yearEventMoList.length - 1]);
        let Mouth = mouthArr[0].list;
        let newMouth = [];
        Mouth.map((data) => {
          for (let k in data) {
            newMouth.push({
              mouth: this.companyMouYear[0] + "-" + k,
              event: data[k],
            });
          }
        });

        this.monthEventMoList = newMouth;
        this.$nextTick(() => {
          // 发展历程
          this.courseSwipers = new Swiper(".courseSwiper", {
            observer: true,
            slidesPerView: 5,
            spaceBetween: 0,
          });
          // 发展历程数据
          this.deveSwiper = new Swiper(".devemobSwiper", {
            observer: true,
            slidesPerView: 2,
            spaceBetween: 0,
          });
        });
      });
    },

    // 获取公司对应年份的发展数据
    developChange(val, index) {
      this.developMoIndex = index;
      let mouthArr = [];
      this.yearEventMoList.forEach((item) => {
        if (val == item.date) {
          mouthArr.push(item.list);
        }
      });
      let List = [];
      mouthArr.map((data) => {
        for (let k in data) {
          List.push({
            date: k,
            event: data[k],
          });
        }
      });
      let newMouth = [];
      for (let k in List[0].event) {
        newMouth.push({
          mouth: val + "-" + k,
          event: List[0].event[k],
        });
      }
      this.monthEventMoList = newMouth;
    },
  },
};
</script>

<style lang="less" scoped>
.mGroup_box {
  width: 100%;

  .meau_list {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5;
  }

  .group_bgc {
    width: 100%;
    height: 80rem;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .jt_txt,
    .jt_eng {
      position: absolute;
      color: #fff;
      font-size: 5.5rem;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-family: pf_cg;
    }

    .jt_eng {
      top: 60%;
      font-size: 4.5rem;
    }
  }

  .brief_introduction {
    width: 100%;
    margin-top: 4rem;
    padding: 0 2.5%;
    box-sizing: border-box;
    color: #666;
    font-size: 4.2rem;
    text-indent: 8rem;

    .briefInt_txt {
      margin-bottom: 1.5rem;
      font-family: pf_cg;
      line-height: 8rem;
    }
  }

  .course {
    width: 100%;
    margin-top: 3.5rem;

    .particular_year {
      width: 100%;
      height: 60rem;
      background: url(../assets/image/fzlcmo.png) no-repeat;
      background-size: 100% 100%;

      .title {
        width: 100%;
        font-family: YouSheBiaoTiHei-2;
        color: #fff;
        text-align: center;
        font-size: 8rem;
        line-height: 20rem;
      }

      .year_list {
        width: 100%;
        height: 30rem;
        overflow: hidden;

        .courseSwiper {
          width: 100%;

          .swiper-slide {
            position: relative;
            color: #fff;
            height: 30rem;

            .course_line {
              width: 100%;
              height: 0.4rem;
              background-color: #fff;
              margin-top: 14.8rem;
            }

            .course_title {
              position: absolute;
              font-family: PingFang Medium;
              font-size: 3.5rem;
              top: 30%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            .outer_circle,
            .inner_circle {
              position: absolute;
              width: 6rem;
              height: 6rem;
              background-color: rgba(255, 255, 255, 0.5);
              border-radius: 50%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            .inner_circle {
              width: 4rem;
              height: 4rem;
            }

            .change_inner {
              background-color: #0052d9;
            }
          }
        }
      }
    }

    .develop_mobile {
      width: 100%;
      height: 40rem;
      background-color: #f9f9f9;

      .devemobSwiper {
        width: 100%;
        height: 40rem;
        overflow: hidden;

        .swiper-slide {
          padding: 0 4rem 0 2rem;
          box-sizing: border-box;

          .date_mobile {
            color: #333333;
            font-size: 4.5rem;
            line-height: 8rem;
            font-family: pf_cg;
            font-weight: 700;
            border-bottom: 0.2rem solid #979797;
            margin-top: 2rem;
          }

          .list_mobuile {
            width: 100%;
            margin-top: 1rem;

            li {
              line-height: 5rem;
              font-size: 3rem;
              color: #666666;
              font-family: pf_cg;
            }
          }
        }
      }
    }
  }

  .prize {
    width: 100%;
    margin: 5rem 0;

    .title {
      width: 100%;
      text-align: center;
      font-size: 8rem;
      font-family: YouSheBiaoTiHei-2;
    }
    .prize_roll {
      width: 100%;
      height: 80rem;
      overflow: hidden;
      margin-bottom: 5rem;
      padding: 0 2.5%;
      box-sizing: border-box;

      .roll_content {
        width: 100%;
        height: 80rem;

        .roll_list {
          width: 100%;
          line-height: 10rem;
          font-size: 4rem;
          font-family: pf_cg;
          border-left: 0.5rem solid #979797;
          padding-left: 4rem;
          box-sizing: border-box;
          position: relative;
          margin-left: 2.5%;

          .circle_box {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            background-color: rgba(0, 82, 217, 0.19);
            position: absolute;
            top: 3.2rem;
            left: -2.3rem;
          }

          .inner_ring {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            background-color: #0052d9;
            position: absolute;
            top: 3.7rem;
            left: -1.7rem;
          }

          .list_txt {
            width: 96%;
          }
        }
      }
    }
  }

  .glory {
    width: 100%;

    .glory_title {
      width: 100%;
      text-align: center;
      font-size: 8rem;
      font-family: YouSheBiaoTiHei-2;
    }

    .honor_list {
      width: 80%;
      height: 120rem;
      margin-bottom: 10rem;
      margin-left: 10%;

      .glorySwiper {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;

        .swiper-slide {
          text-align: center;
          background: linear-gradient(180deg, #397ae5 0%, #9ed5fd 100%);
          height: calc((100% - 1rem) / 2);
          position: relative;
          box-shadow: 0px 0px 10px #ebebeb;

          .flower {
            width: 90%;
            height: 4rem;
            margin: 2rem auto 0 auto;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .el-image {
            width: 90%;
            height: 35rem;
            margin: 4rem 0 2rem 0;
          }

          .honor_txt {
            width: 100%;
            box-sizing: border-box;
            color: #333333;
            font-size: 3rem;
            font-family: pf_cg;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }
}
</style>
