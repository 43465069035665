<template>
  <div class="m_service">
    <div class="meau_list">
      <MobileHead></MobileHead>
    </div>
    <!-- 背景图 -->
    <div class="group_bgc">
      <img src="../assets/image/cpyymo.png" />
      <div class="cp_txt">产品应用</div>
      <div class="cp_eng">Product Application</div>
    </div>
    <!-- 产品列表 -->
    <div
      class="product_list"
      v-for="(item, index) in productList"
      :key="index"
      @click="goServiceDetails(item.id)"
    >
      <div class="imgBox">
        <el-image :src="item.imgUrl" fit="scale-down"></el-image>
        <div class="texts">{{ item.name }}</div>
      </div>
      <div class="text_box" v-html="item.content"></div>
      <div class="line"></div>
    </div>
    <!-- 底部 -->
    <MobileFoot></MobileFoot>
  </div>
</template>

<script>
import MobileHead from "./mobileHead.vue";
import MobileFoot from "./mFoot.vue";
import { productInfo } from "../api/modules/request.js";
import { baseURL } from "../api/baseUrl.js";
export default {
  components: {
    MobileHead,
    MobileFoot,
  },
  data() {
    return {
      productList: null,
    };
  },
  mounted() {
    this.getProductData();
  },
  methods: {
    // 查看详情
    goServiceDetails(val) {
      this.$router.push({
        path: "/serviceDetails",
        query: {
          id: val,
        },
      });
    },

    // 获取产品列表
    getProductData() {
      productInfo().then((res) => {
        res.data.forEach((e) => {
          e.imgUrl = baseURL + e.imgUrl.substring(1);
        });
        this.productList = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.m_service {
  width: 100%;

  .meau_list {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5;
  }

  .group_bgc {
    width: 100%;
    height: 80rem;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .cp_txt,
    .cp_eng {
      position: absolute;
      color: #fff;
      font-size: 5.5rem;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-family: pf_cg;
    }

    .cp_eng {
      top: 60%;
      font-size: 4.5rem;
    }
  }

  .product_list {
    width: 100%;
    margin-top: 8rem;
    padding: 0 2.5%;
    box-sizing: border-box;

    .imgBox {
      width: 100%;

      .el-image {
        width: 60%;
        height: 40rem;
        margin-left: 20%;
      }

      .texts {
        width: 100%;
        color: #0052d9;
        font-size: PingFang Med;
        text-align: center;
        font-size: 5.5rem;
        font-weight: 700;
      }
    }

    .text_box {
      width: 100%;
      text-align: center;
      font-size: 4rem;
      font-family: pf_cg;
      margin-top: 2rem;
      line-height: 7rem;
      margin-bottom: 4rem;
    }

    .line {
      width: 100%;
      height: 1px;
      background-color: #979797;
    }
  }
}
</style>
