<template>
  <div
    class="service_details"
    :style="{ width: screenWidth > 640 ? '' : '100%' }"
  >
    <div v-if="screenWidth > 640" style="width: 100%">
      <div class="serdeta_banner">
        <img src="../assets/image/cpyy.png" />
      </div>
      <div v-html="content" class="content_box"></div>
      <!-- 返回产品主页 -->
      <div class="back_product">
        <div class="product_border"></div>
        <div class="product_txt" @click="backProduct">
          <img src="../assets/image/back.png" alt="" />
          <div class="txt">返回产品列表</div>
        </div>
      </div>
    </div>
    <div v-else-if="screenWidth <= 640" style="width: 100%">
      <MServiceDetails></MServiceDetails>
    </div>
  </div>
</template>

<script>
import { queryProduct } from "../api/modules/request.js";
import { Loading } from "element-ui";
import MServiceDetails from "../components/mServiceDetails.vue";
export default {
  components: {
    MServiceDetails,
  },
  data() {
    return {
      content: "加载中……",
      loading: null,
      screenWidth: null,
    };
  },
  created() {
    this.screenWidth = document.documentElement.clientWidth;
  },
  mounted() {
    this.getProductData();
  },
  methods: {
    // 获取产品详情
    getProductData() {
      this.loading = Loading.service({
        lock: true,
        text: "加载中……",
        background: "rgba(0, 0, 0, 0.2)",
      });
      queryProduct({
        productApplicationId: this.$route.query.id,
      }).then((res) => {
        if (res.data.length != 0) {
          this.content = res.data[0].content;
          this.loading.close();
        } else if (res.data.length == 0) {
          setTimeout(() => {
            this.loading.close();
            this.content = "暂无数据";
          }, 5000);
        }
      });
    },

    // 返回产品列表
    backProduct() {
      this.$router.push("/service");
    },
  },
};
</script>

<style lang="less">
.service_details {
  width: 100%;

  .serdeta_banner {
    width: 100%;
    height: 450px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .content_box {
    width: 1200px;
    margin: 0 auto 50px auto;
  }

  .back_product {
    width: 1200px;
    margin: auto;

    .product_border {
      width: 100%;
      height: 1px;
      background-color: rgb(167, 167, 167);
    }

    .product_txt {
      display: flex;
      margin: 20px 0 50px 0;

      .txt {
        width: 100%;
        line-height: 25px;
        text-align: left;
        color: #666666;
        font-size: 1.3rem;
        font-family: PingFang Medium;
        cursor: pointer;
        display: flex;
      }

      img {
        max-width: 19px;
        max-height: 15px;
        margin: 5px 5px 0 0;
      }
    }
  }
}
</style>
