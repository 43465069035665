<template>
  <div class="resuBox">
    <div v-if="screenWidth > 640" style="width: 100%">
      <!-- banner -->
      <div class="banner">
        <img src="../assets/image/bannerT.png" alt="" />
      </div>
      <!-- 职位 -->
      <div class="post">
        <div class="name">{{ name }}</div>
        <div class="delivery" @click="adDdeliverResume('contactInfoList')">
          提交简历
        </div>
      </div>
      <!-- 联系信息 -->
      <div class="contactInfo">联系信息</div>
      <div class="form">
        <el-form
          label-position="top"
          label-width="80px"
          :model="contactInfoList"
          style="display: flex; flex-wrap: wrap"
          :rules="rules"
          ref="contactInfoList"
        >
          <el-form-item
            label="姓名"
            style="width: 49%; margin-right: 1.5%"
            prop="name"
          >
            <el-input v-model="contactInfoList.name"></el-input>
          </el-form-item>
          <el-form-item label="电话" style="width: 49%" prop="tel">
            <el-input v-model="contactInfoList.tel"></el-input>
          </el-form-item>
          <el-form-item
            label="电子邮箱"
            style="width: 49%; margin-right: 1.5%"
            prop="mail"
          >
            <el-input v-model="contactInfoList.mail"></el-input>
          </el-form-item>
          <el-form-item
            label="期望工作地点"
            style="width: 49%"
            prop="workPlace"
          >
            <el-input
              v-model="contactInfoList.workPlace"
              placeholder="可选地：无锡，北京，西安，杭州，福建，新疆"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 其他信息 -->
      <div class="otherInfo">
        <div class="tlt">自我评价</div>
        <div class="textField">
          <el-input
            type="textarea"
            :rows="10"
            placeholder="请输入内容"
            v-model="textarea"
            clearable
          >
          </el-input>
        </div>
      </div>
      <!-- 附件 -->
      <div class="enclosure">
        <div class="ttle">作品或其他附件</div>
        <div class="drag">
          <el-upload
            class="upload-demo"
            :action="resumeUrl"
            :on-success="handleSuccessChange"
            :before-upload="beforeUpload"
            :file-list="fileList"
            :limit="1"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
      </div>
      <!-- 返回上一级 -->
      <div class="backe">
        <div @click="backList">返回列表</div>
      </div>
    </div>
    <div v-else-if="screenWidth <= 640" style="width: 100%">
      <MresumeDelivery></MresumeDelivery>
    </div>
  </div>
</template>

<script>
import { applyFor, resume } from "../api/modules/request.js";
import { baseURL } from "../api/baseUrl.js";
import MresumeDelivery from "../components/mresumeDelivery.vue";
export default {
  data() {
    return {
      name: null,
      contactInfoList: {
        name: null,
        tel: null,
        mail: null,
        workPlace: null,
        resumeAnnexUrl: null,
        joinUsId: "",
      },
      rules: {
        name: [{ required: true, message: "请填写姓名", trigger: "blur" }],
        tel: [
          { required: true, message: "请填写联系电话", trigger: "blur" },
          {
            pattern: /^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/,
            message: "请填正确的电话号码",
            trigger: ["blur", "change"],
          },
        ],
        mail: [
          { required: true, message: "请留下你的邮箱哦", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        workPlace: [
          { required: true, message: "请选择期望工作地", trigger: "blur" },
        ],
      },
      textarea: null,
      fileList: [],
      resumeUrl: baseURL + "candidate-info/resume",
      screenWidth: null,
    };
  },
  components: {
    MresumeDelivery,
  },
  created() {
    this.screenWidth = document.documentElement.clientWidth;
    let data = this.$route.query.val;
    this.name = JSON.parse(data).jobName;
    this.contactInfoList.joinUsId = JSON.parse(data).id;
  },
  mounted() {},
  methods: {
    // 附件上传之前
    beforeUpload(file) {
      // const fileSzie = file.size / 1024 / 1024 < 2;
      // if (!fileSzie) {
      //   this.$message({
      //     message: "文件太大，不能超过2M",
      //     type: "danger",
      //   });
      // }
      // return fileSzie;
    },

    // 上传附件成功
    handleSuccessChange(file) {
      if (file.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        this.contactInfoList.resumeAnnexUrl = file.data;
      } else {
        this.$message({
          message: "上传失败",
          type: "error",
        });
      }
    },

    // 投递简历
    adDdeliverResume(contactInfoList) {
      this.$refs[contactInfoList].validate((valid) => {
        if (valid) {
          if (this.contactInfoList.resumeAnnexUrl == null) {
            this.$message({
              message: "请上传附件简历",
              type: "warning",
            });
          } else if (this.contactInfoList.resumeAnnexUrl != null) {
            applyFor({
              content: this.textarea,
              name: this.contactInfoList.name,
              joinUsId: this.contactInfoList.joinUsId,
              mail: this.contactInfoList.mail,
              resumeAnnexUrl: this.contactInfoList.resumeAnnexUrl,
              tel: this.contactInfoList.tel,
              workPlace: this.contactInfoList.workPlace,
            })
              .then((res) => {
                if (res.code == 200) {
                  this.$message({
                    message: "简历投递成功",
                    type: "success",
                  });
                } else {
                  this.$message({
                    message: res.msg,
                    type: "danger",
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      });
    },

    // 返回列表
    backList() {
      this.$router.push("/about");
    },
  },
};
</script>

<style lang="less">
.resuBox {
  width: 100%;
  position: relative;

  .topNav {
    width: 100%;
    height: 80px;
    background-color: #fff;
    display: flex;
    position: fixed;
    top: 0;
    z-index: 10;

    .companyLogo {
      width: 60px;
      height: 32px;
      margin: 24px 388px 0 360px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .navList {
      height: 100px;

      ul {
        display: flex;

        li {
          height: 34px;
          margin-top: 23px;
          margin-right: 50px;
          color: #333333;
          cursor: pointer;
          font-size: 1rem;
          font-family: pf_cg;
          padding: 0 5px;
          box-sizing: border-box;
          position: relative;
        }

        .header_active {
          color: #0052d9;
          border-bottom: 2px solid #0052d9;
        }

        li:after {
          content: "";
          width: 0;
          height: 2px;
          background: #0052d9;
          position: absolute;
          top: 100%;
          left: 50%;
          transition: all 0.8s;
        }

        li:hover {
          color: #0052d9;
        }

        li:hover:after {
          left: 0;
          width: 100%;
        }
      }
    }
  }

  .banner {
    width: 100%;
    height: 450px;
    margin-top: 80px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .post {
    width: 100%;
    height: 80px;
    display: flex;
    border-bottom: 1px solid #ccc;
    padding: 0 360px;
    box-sizing: border-box;
    justify-content: space-between;

    .name {
      height: 50px;
      color: #333333;
      font-size: 2.25rem;
      margin-top: 15px;
      font-family: PingFang Medium;
    }

    .delivery {
      width: 150px;
      height: 40px;
      line-height: 40px;
      margin-top: 20px;
      text-align: right;
      background-color: #0052d9;
      text-align: center;
      color: #ffffff;
      font-size: 1.125rem;
      font-family: PingFang Medium;
      cursor: pointer;
    }
  }

  .contactInfo {
    width: 100%;
    height: 70px;
    background-color: #fff;
    line-height: 70px;
    color: #666666;
    font-size: 1.5rem;
    font-family: pf_cg;
    padding: 0 360px;
    box-sizing: border-box;
  }

  .form {
    width: 1200px;
    margin: 0 auto;

    .el-form-item__label {
      padding: 0 !important;
      color: #666666;
      font-family: pf_cg;
    }
  }

  .otherInfo {
    width: 1200px;
    margin: 0 auto;

    .tlt {
      color: #666666;
      font-size: 1.25rem;
      margin: 30px 0;
      font-family: pf_cg;
    }

    .textField {
      margin-bottom: 50px;
    }
  }

  .enclosure {
    width: 1200px;
    margin: 0 auto;

    .ttle {
      color: #666666;
      font-size: 1.25rem;
      margin: 30px 0;
      font-family: pf_cg;
    }

    .drag {
      width: 100%;
      height: 250px;
      border: 1px solid #d6d6d6;
      margin-bottom: 50px;
      border-radius: 5px;
      text-align: center;
      padding-top: 100px;
      box-sizing: border-box;

      .el-button {
        background-color: #efefef !important;
        border: #ffffff;
        color: #333333;
        font-size: 1rem;
        font-family: PingFang Medium;
        padding: 10px 25px;
        box-sizing: border-box;
      }
    }
  }

  .backe {
    width: 176px;
    height: 46px;
    position: relative;
    text-align: center;
    line-height: 46px;
    background-color: #ffffff;
    border-radius: 23px;
    margin: 40px auto 100px auto;
    color: #666666;
    font-size: 1.3rem;
    font-family: PingFang Medium;
    cursor: pointer;
  }
}
</style>
