<template>
  <div class="groupProfile_box">
    <div v-if="screenWidth > 640" style="width: 100%">
      <!-- banner图 -->
      <div class="banner_img">
        <img src="../assets/image/jt.jpg" />
      </div>
      <!-- 公司简介 -->
      <a name="briefInt"></a>
      <div class="brief_int">
        <div
          class="briefInt_txt"
          v-for="(item, index) in briefIntList"
          :key="index"
        >
          {{ item.content }}
        </div>
      </div>
      <!-- 公司分布 -->
      <a name="address"></a>
      <div class="company_address">
        <div class="address_title">{{ addressTitle }}</div>
        <div class="address_english">Company distribution</div>
        <div class="address_map">
          <div class="map_box">
            <img v-if="mapIndex == 0" src="../assets/image/wxmap.png" />
            <img v-else-if="mapIndex == 1" src="../assets/image/bjmap.png" />
            <img v-else-if="mapIndex == 2" src="../assets/image/xamap.png" />
            <img v-else-if="mapIndex == 3" src="../assets/image/hzmap.png" />
            <img v-else-if="mapIndex == 4" src="../assets/image/fjmap.png" />
            <img v-else-if="mapIndex == 5" src="../assets/image/xjmap.png" />
          </div>
          <div class="map_list">
            <div class="left_maplist">
              <ul>
                <li
                  v-for="(i, index) in mapList"
                  :key="index"
                  @click="mapChange(index)"
                  :class="index == mapIndex ? 'liChange' : ''"
                >
                  {{ i.name }}
                </li>
              </ul>
            </div>
            <div class="right_maplist">
              <ul>
                <li
                  v-for="(i, index) in mapList"
                  :key="index"
                  @click="mapChange(index)"
                  :class="index == mapIndex ? 'liChange' : ''"
                >
                  {{ i.title }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- 发展历程轴线 -->
      <a name="developmentHistory"></a>
      <div class="develop_history">
        <div class="develop_title">{{ developTitle }}</div>
        <div class="develop_english">development history</div>
        <div class="history_box">
          <div class="swiper developSwiper">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in companyYear"
                :key="index"
                @click="developChange(item, index)"
              >
                <div class="axis_title">{{ item }}</div>
                <div class="time_axis"></div>
                <div class="big_box"></div>
                <div
                  class="small_box"
                  :class="[developIndex == index ? 'small_box_change' : '']"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 发展历程史（数据） -->
      <div class="develop_data">
        <div class="swiper developDataSwiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in monthEventList"
              :key="index"
            >
              <div class="data_title">{{ item.mouth }}</div>
              <div class="data_list">
                <ul>
                  <li v-for="(i, index) in item.event" :key="index">{{ i }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 行业奖项 -->
      <a name="prize"></a>
      <div class="industry_awards">
        <div class="prize_title">{{ prizeTitle }}</div>
        <div class="prize_english">Industry Awards</div>
        <div class="prize_list">
          <vue-seamless-scroll
            :data="prizeList"
            :class-option="optionSingleHeightTime"
            class="seamless_warp"
          >
            <ul>
              <li v-for="(item, index) in prizeList" :key="index">
                <div></div>
                <div></div>
                <div>{{ item.content }}</div>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
      <!-- 荣誉展示 -->
      <div class="honor_display">
        <div class="honor_title">荣誉展示</div>
        <div class="honor_english">Honor display</div>
        <div class="honor_list">
          <div class="swiper-container swiper2">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in honorList"
                :key="index"
              >
                <div class="flower">
                  <img src="../assets/image/Medal.png" />
                </div>
                <el-image
                  :preview-src-list="[item.imgUrl]"
                  :src="item.imgUrl"
                  fit="contain"
                ></el-image>
                <div class="honor_txt">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="screenWidth <= 640" style="width: 100%">
      <MGroup></MGroup>
    </div>
  </div>
</template>

<script>
import {
  groupIntroduction,
  history,
  prize,
  honor,
} from "../api/modules/request.js";
import { baseURL } from "../api/baseUrl.js";
import { GropMapList } from "../assets/js/public.js";
import Swiper from "swiper";
import "swiper/css/swiper.css";
import MGroup from "../components/mGroup.vue";
export default {
  components: {
    MGroup,
  },
  data() {
    return {
      briefIntList: null,
      addressTitle: "公司分布",
      mapList: null,
      mapIndex: 0,
      developTitle: "发展历程",
      deveSwiper: null,
      deveDataSwiper: null,
      developIndex: 0,
      companyYear: null,
      yearEventList: null,
      monthEventList: null,
      prizeTitle: "行业奖项",
      prizeList: [],
      honorTitle: "荣誉展示",
      honorSwiper: null,
      honorList: null,
      screenWidth: null,
    };
  },
  computed: {
    optionSingleHeightTime() {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: this.prizeList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
      };
    },
  },
  created() {
    this.screenWidth = document.documentElement.clientWidth;
    if (window.location.hash.indexOf("#") >= 0) {
      $("html.body").animate({});
    }
  },
  mounted() {
    // 荣誉展示数据
    var swiper = new Swiper(".swiper2", {
      autoplay: {
        dalay: 0,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      speed: 1000,
      observer: true,
      observeParents: true,
      slidesPerView: 4,
      slidesPerColumn: 2,
      spaceBetween: 25,
      grabCursor: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
    this.mapList = GropMapList;
    this.getBriefInt();
    this.getYearsArr();
    this.getPrizeData();
    this.getHonorData();
  },
  methods: {
    // 获取公司简介
    getBriefInt() {
      groupIntroduction().then((res) => {
        this.briefIntList = res.data;
      });
    },

    // 公司分布切换
    mapChange(index) {
      this.mapIndex = index;
    },

    // 获取公司发展的总年份
    getYearsArr() {
      history().then((res) => {
        let historyList = [];
        res.data.map((data) => {
          for (let k in data) {
            historyList.push({
              date: k,
              list: data[k],
            });
          }
        });
        let arrYears = [];
        historyList.forEach((item) => {
          arrYears.push(item.date);
        });
        this.companyYear = arrYears.reverse();
        this.yearEventList = historyList;
        let mouthArr = [];
        mouthArr.push(this.yearEventList[this.yearEventList.length - 1]);
        let Mouth = mouthArr[0].list;
        let newMouth = [];
        Mouth.map((data) => {
          for (let k in data) {
            newMouth.push({
              mouth: this.companyYear[0] + "-" + k,
              event: data[k],
            });
          }
        });
        this.monthEventList = newMouth;
        this.$nextTick(() => {
          // 发展历程
          this.deveSwiper = new Swiper(".developSwiper", {
            observer: true,
            observeParents: true,
            slidesPerView: 8,
            spaceBetween: 0,
            grabCursor: true,
          });
          // 发展历程数据
          this.deveDataSwiper = new Swiper(".developDataSwiper", {
            observer: true,
            observeParents: true,
            slidesPerView: 4,
            spaceBetween: 0,
            grabCursor: true,
          });
        });
      });
    },

    // 获取公司对应年份的发展数据
    developChange(val, index) {
      this.developIndex = index;
      let mouthArr = [];
      this.yearEventList.forEach((item) => {
        if (val == item.date) {
          mouthArr.push(item.list);
        }
      });
      let List = [];
      mouthArr.map((data) => {
        for (let k in data) {
          List.push({
            date: k,
            event: data[k],
          });
        }
      });
      let newMouth = [];
      for (let k in List[0].event) {
        newMouth.push({
          mouth: val + "-" + k,
          event: List[0].event[k],
        });
      }
      this.monthEventList = newMouth;
    },

    // 获取行业奖项数据
    getPrizeData() {
      prize().then((res) => {
        res.data.forEach((i) => {
          let newArr = i.awardDate.split("");
          newArr[4] = ".";
          i.content = newArr.join("") + " " + i.content;
        });
        this.prizeList = res.data;
      });
    },

    // 获取荣誉展示数据
    getHonorData() {
      honor().then((res) => {
        res.data.forEach((i) => {
          i.imgUrl = baseURL + i.imgUrl.substring(1);
        });
        this.honorList = res.data;
      });
    },
  },
};
</script>

<style lang="less">
.groupProfile_box {
  width: 100%;

  .banner_img {
    width: 100%;
    height: 450px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .brief_int {
    width: 1200px;
    margin: 50px auto 0 auto;

    .briefInt_txt {
      font-size: 1.125rem;
      color: #666666;
      font-family: pf_cg;
      line-height: 35px;
      margin-bottom: 28px;
    }
  }

  .company_address {
    width: 1200px;
    margin: 60px auto 100px auto;

    .address_title,
    .address_english {
      color: #333333;
      font-size: 2.25rem;
      text-align: center;
      height: 62px;
      font-family: YouSheBiaoTiHei-2;
    }

    .address_english {
      font-size: 1.1rem;
      color: #999999;
      margin: 0 0 20px 0;
    }

    .address_map {
      width: 100%;
      display: flex;

      .map_box {
        width: 636px;
        height: 100%;
        margin-right: 88px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .map_list {
        width: 400px;
        height: 420px;
        margin-top: 45px;
        display: flex;

        .left_maplist {
          width: 100px;
          height: 100%;
          border-right: 1px solid #979797;

          li {
            height: 70px;
            width: 100%;
            line-height: 70px;
            text-align: center;
            cursor: pointer;
            color: #8d8d8d;
            font-size: 0.9rem;
          }

          .liChange {
            color: #000;
            font-family: PingFang Medium;
          }
        }

        .right_maplist {
          width: 300px;
          height: 100%;

          li {
            height: 70px;
            width: 100%;
            line-height: 70px;
            padding-left: 10px;
            box-sizing: border-box;
            cursor: pointer;
            color: #666666;
            font-size: 0.9rem;
            border-left: 2px solid #fff;
          }

          .liChange {
            color: #0052d9;
            font-family: PingFang Medium;
            border-left: 2px solid #0052d9;
          }
        }
      }
    }
  }

  .develop_history {
    width: 100%;
    height: 400px;
    background: url(../assets/image/fzlc.png) no-repeat;
    background-size: 100% 100%;
    padding-top: 30px;
    box-sizing: border-box;

    .develop_title,
    .develop_english {
      width: 100%;
      text-align: center;
      font-size: 2.25rem;
      color: rgb(255, 255, 255);
      font-family: YouSheBiaoTiHei-2;
    }

    .develop_english {
      font-size: 1.1rem;
      color: #c7c6c6;
      margin: 0 0 40px 0;
    }

    .history_box {
      width: 1200px;
      height: 75px;
      margin: 0 auto;
      overflow: hidden;

      .swiper .swiper-wrapper {
        width: 100%;
        height: 75px;

        .swiper-slide {
          width: 150px;
          height: 75px;
          position: relative;

          .axis_title {
            width: 40px;
            text-align: center;
            margin-left: 55px;
            color: #fff;
            font-size: 1rem;
            font-family: PingFang Medium;
          }

          .time_axis {
            width: 100%;
            height: 2px;
            position: absolute;
            background-color: #fff;
            top: 70%;
            transform: translateY(-50%);
          }

          .big_box,
          .small_box {
            width: 40px;
            height: 40px;
            position: absolute;
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 50%;
            top: 70%;
            left: 55px;
            transform: translateY(-50%);
          }

          .small_box {
            left: 65px;
            width: 20px;
            height: 20px;
            background-color: #fff;
          }

          .small_box_change {
            background-color: #0052d9;
          }
        }
      }
    }
  }

  .develop_data {
    width: 100%;
    height: 250px;
    background-color: #f9f9f9;
    margin-bottom: 100px;

    .developDataSwiper {
      width: 1200px;
      margin: 0 auto;
      height: 250px;
      overflow: hidden;

      .swiper-slide {
        width: 300px;
        height: 250px;
        padding: 20px;
        box-sizing: border-box;

        .data_title {
          width: 100%;
          color: #333333;
          font-size: 1.25rem;
          border-bottom: 1px solid #979797;
          padding-bottom: 5px;
          box-sizing: border-box;
        }

        .data_list {
          width: 100%;
          margin-top: 10px;

          ul {
            width: 100%;

            li {
              color: #666666;
              font-size: 1rem;
              font-family: pf_cg;
              line-height: 28px;
            }
          }
        }
      }
    }
  }

  .industry_awards {
    width: 1200px;
    margin: 0 auto;

    .prize_title,
    .prize_english {
      font-size: 2.25rem;
      color: #333333;
      font-family: YouSheBiaoTiHei-2;
      text-align: center;
      height: 50px;
    }

    .prize_english {
      color: #999999;
      font-size: 1.1rem;
      margin: 10px 0 30px 0;
    }

    .prize_list {
      width: 1200px;
      height: 408px;
      margin-bottom: 120px;

      .seamless_warp {
        width: 100%;
        height: 408px;
        overflow: hidden;
        padding-left: 10px;
        box-sizing: border-box;

        li {
          width: 100%;
          height: 55px;
          line-height: 55px;
          color: #333333;
          font-size: 1rem;
          font-family: pf_cg;
          margin-left: 30px;
          border-left: 1px solid #979797;
          display: flex;
          position: relative;

          div:nth-child(1) {
            width: 20px;
            height: 20px;
            background-color: rgba(0, 82, 217, 0.19);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -10px;
          }

          div:nth-child(2) {
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: #0052d9;
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
            left: -5px;
          }

          div:nth-child(3) {
            margin-left: 20px;
          }
        }
      }
    }
  }

  .honor_display {
    width: 1200px;
    margin: 0 auto;

    .honor_title,
    .honor_english {
      font-size: 2.25rem;
      color: #333333;
      font-family: YouSheBiaoTiHei-2;
      text-align: center;
      height: 50px;
    }

    .honor_english {
      font-size: 1.1rem;
      color: #999999;
      margin: 10px 0 40px 0;
    }

    .honor_list {
      width: 100%;
      height: 560px;
      margin-bottom: 100px;

      .swiper2 {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;

        .swiper-slide {
          text-align: center;
          background: #fbfbfb;
          height: calc((100% - 30px) / 2);
          position: relative;
          box-shadow: 0px 0px 10px #ebebeb;

          .flower {
            position: absolute;
            top: 10px;
            left: 5%;
            width: 90%;
            height: 25px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .el-image {
            width: 120px;
            height: 140px;
            margin-top: 60px;
          }

          .honor_txt {
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;
            color: #333333;
            font-size: 0.9rem;
            font-family: pf_cg;
          }
        }

        .swiper-slide:hover {
          background: linear-gradient(180deg, #397ae5 0%, #9ed5fd 100%);
        }
      }
    }
  }
}
</style>
