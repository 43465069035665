<template>
  <div class="m_solutionDetail">
    <div class="meau_list">
      <MobileHead></MobileHead>
    </div>
    <!-- 背景 -->
    <div class="bannerImag" style="height: 80rem">
      <div v-if="type == 0" class="titr" style="font-size: 5.5rem">智慧能源</div>
      <div v-else-if="type == 1" class="titr" style="font-size: 5.5rem">数字公路</div>
      <div v-else-if="type == 2" class="titr" style="font-size: 5.5rem">智慧水务</div>
      <div v-else-if="type == 3" class="titr" style="font-size: 5.5rem">智慧文旅</div>
      <div v-else-if="type == 4" class="titr" style="font-size: 5.5rem">数字乡村</div>
      <img v-if="type == 0" src="../assets/image/zhsdxq.png" />
      <img v-else-if="type == 1" src="../assets/image/szglxq.png" />
      <img v-else-if="type == 2" src="../assets/image/zhswxq.png" />
      <img v-else-if="type == 3" src="../assets/image/zhwlba.png" />
      <img v-else-if="type == 4" src="../assets/image/szxcxq.png" />
    </div>
    <!-- 详情 -->
    <div v-html="content" class="details_box"></div>
  </div>
</template>

<script>
import MobileHead from "./mobileHead.vue";
import { groupDetails } from "../api/modules/request.js";
export default {
  components: {
    MobileHead,
  },
  data() {
    return {
      content: "",
      type: null,
    };
  },
  mounted() {
    this.type = this.$route.query.type;
    groupDetails({
      systemIntroduceId: this.$route.query.id,
    }).then((res) => {
      if (res.data.length != 0) {
        this.content = res.data[0].content;
      } else if (res.data.length == 0) {
        setTimeout(() => {
          this.content = "暂无数据";
        }, 5000);
      }
    });
  },
  methods: {},
};
</script>

<style lang="less">
.m_solutionDetail {
  width: 100%;

  .meau_list {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5;
  }

  .bannerImag {
    width: 100%;
    height: 80rem;
    text-align: center;
    font-family: PingFang Medium;
    margin-bottom: 2rem;

    .titr {
      font-size: 5.5rem;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .details_box {
    width: 100%;
    padding: 0 3%;
    box-sizing: border-box;
    font-family: pf_cg;
    margin-bottom: 20rem;

    p {
      font-size: 4.1rem !important;
    }

    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
</style>
