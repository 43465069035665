<template>
  <div class="mm_enterpriseInfoDetail">
    <div class="meau_list">
      <MobileHead></MobileHead>
    </div>
    <!-- 背景图 -->
    <div class="group_bgc">
      <img src="../assets/image/3.png" />
    </div>
    <!-- 详情 -->
    <div v-html="content" class="details_box"></div>
  </div>
</template>

<script>
import MobileHead from "./mobileHead.vue";
import { queryIndustry, queryEnterprise } from "../api/modules/request.js";
export default {
  components: {
    MobileHead,
  },
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    if (Number(this.$route.query.type) === 0) {
      queryEnterprise({
        enterpriseNewsId: this.$route.query.id,
      }).then((res) => {
        if (res.data.length != 0) {
          this.content = res.data[0].content;
        } else if (res.data.length == 0) {
          setTimeout(() => {
            this.loading.close();
            this.content = "暂无数据";
          }, 5000);
        }
      });
    } else {
      queryIndustry({
        industryNewsId: this.$route.query.id,
      }).then((res) => {
        if (res.data.length != 0) {
          this.content = res.data[0].content;
        } else if (res.data.length == 0) {
          setTimeout(() => {
            this.loading.close();
            this.content = "暂无数据";
          }, 5000);
        }
      });
    }
  },
  methods: {},
};
</script>

<style lang="less">
.mm_enterpriseInfoDetail {
  width: 100%;

  .meau_list {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5;
  }

  .group_bgc {
    width: 100%;
    height: 80rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .details_box {
    width: 100%;
    padding: 0 3%;
    box-sizing: border-box;
    font-family: pf_cg;
    margin-bottom: 20rem;

    p {
      font-size: 4.1rem !important;
    }
    
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
</style>
