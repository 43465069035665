<template>
  <div class="platformDetailsBox">
    <div v-if="screenWidth > 640" style="width: 100%">
      <div class="bannerImag">
        <div v-if="type == 0" class="titr">智慧能源</div>
        <div v-else-if="type == 1" class="titr">数字公路</div>
        <div v-else-if="type == 2" class="titr">智慧水务</div>
        <div v-else-if="type == 3" class="titr">智慧文旅</div>
        <div v-else-if="type == 4" class="titr">数字乡村</div>
        <img v-if="type == 0" src="../assets/image/zhsdxq.png" />
        <img v-else-if="type == 1" src="../assets/image/szglxq.png" />
        <img v-else-if="type == 2" src="../assets/image/zhswxq.png" />
        <img v-else-if="type == 3" src="../assets/image/zhwlba.png" />
        <img v-else-if="type == 4" src="../assets/image/szxcxq.png" />
      </div>
      <div v-html="content" class="detailsBox ql-snow ql-editor"></div>
      <!-- 返回上一级 -->
      <div class="back_product">
        <div class="product_border"></div>
        <div class="product_txtso" @click="backList">
          <img src="../assets/image/back.png" alt="" />
          <div class="txt">返回产品列表</div>
        </div>
      </div>
    </div>
    <div v-else-if="screenWidth <= 640" style="width: 100%">
      <MSolutionDetail></MSolutionDetail>
    </div>
  </div>
</template>

<script>
import { groupDetails } from "../api/modules/request.js";
import { Loading } from "element-ui";
import MSolutionDetail from "../components/mSolutionDetail.vue";
export default {
  data() {
    return {
      content: "",
      type: null,
      loading: null,
      screenWidth: null,
    };
  },
  components: {
    MSolutionDetail,
  },
  created() {
    this.screenWidth = document.documentElement.clientWidth;
    this.loading = Loading.service({
      lock: true,
      text: "加载中……",
      background: "rgba(0, 0, 0, 0.2)",
    });
    this.type = this.$route.query.type;
    groupDetails({
      systemIntroduceId: this.$route.query.id,
    }).then((res) => {
      if (res.data.length != 0) {
        this.content = res.data[0].content;
        this.loading.close();
      } else if (res.data.length == 0) {
        setTimeout(() => {
          this.loading.close();
          this.content = "暂无数据";
        }, 5000);
      }
    });
  },
  mounted() {},
  methods: {
    // 返回上一级
    backList() {
      this.$router.push({
        path: "/solution",
        query: {
          type: this.$route.query.type,
          digitalTwinsId: Number(this.$route.query.type) + 1,
        },
      });
    },
  },
};
</script>

<style lang="less">
.platformDetailsBox {
  width: 100%;

  .bannerImag {
    width: 100%;
    height: 450px;
    position: relative;

    .titr {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 3rem;
      color: #ffffff;
      letter-spacing: 2px;
      font-family: PingFang Medium;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .detailsBox {
    width: 1200px;
    margin: 100px auto 0 auto;
  }

  .back_product {
    width: 1200px;
    margin: auto;

    .product_border {
      width: 100%;
      height: 1px;
      background-color: rgb(167, 167, 167);
    }

    .product_txtso {
      display: flex;
      margin: 20px 0 50px 0;

      .txt {
        width: 100%;
        line-height: 25px;
        text-align: left;
        color: #666666;
        font-size: 1.3rem;
        font-family: PingFang Medium;
        cursor: pointer;
        display: flex;
      }

      img {
        width: 19px;
        height: 15px;
        margin: 5px 5px 0 0;
      }
    }
  }
}
</style>
