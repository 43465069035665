<template>
  <div class="home_box">
    <div v-if="screenWidth > 640" style="width: 100%">
      <!-- 轮播图 -->
      <div class="rotation_chart" ref="piediv">
        <el-carousel
          height="865px"
          :autoplay="true"
          :interval="2500"
          ref="carousel"
        >
          <el-carousel-item v-for="(item, index) in rotationList" :key="index">
            <img :src="item.imgUrl" style="width: 100%; height: 100%" />
          </el-carousel-item>
        </el-carousel>
        <div class="cover"></div>
      </div>
      <!-- 公司宗旨 -->
      <div class="purpose">
        <div class="purpose_title">
          <div class="purpsoe_chinese">企业文化</div>
          <div class="purpsoe_english">corporate culture</div>
        </div>
        <div class="purpose_banner">
          <div class="pb_img" v-for="(item, index) in purposeList" :key="index">
            <img :src="item" />
          </div>
        </div>
      </div>
      <!-- 数据类型 -->
      <div class="data_type">
        <div class="dt_banner">
          <div class="tb_title">{{ dataTypeTitle }}</div>
          <div class="tb_english">
            NEW3S Technology Group · Create a digital twin
            three-dimensional world
          </div>
          <div class="tb_list">
            <div class="tb_left" @mouseover="overTb" @mouseleave="leaveTb">
              <div class="swiper-container tbSwiter">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="(item, index) in dataTypeList"
                    :key="index"
                  >
                    <img :src="item.imgUrl" />
                  </div>
                </div>
              </div>
            </div>
            <div class="tb_right">
              <div class="tb_content">
                <div class="names">
                  {{ dataTypeList[Number(currentIndex)].title }}
                </div>
                <div
                  class="contents"
                  v-html="dataTypeList[Number(currentIndex)].content"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 解决方案轮播图 -->
      <div class="solution">
        <div class="solution_banner">
          <el-carousel height="546px" :autoplay="true" :interval="3000">
            <el-carousel-item
              v-for="(item, index) in solutionList"
              :key="index"
              @click.native="solutionBtn(item)"
            >
              <div class="soluba_title">
                <div></div>
                <div>{{ item.name }}</div>
              </div>
              <div class="soluba_box">
                <div class="soluba_txt">
                  {{ item.content }}
                </div>
                <div class="solution_image">
                  <img :src="item.imgUrl" />
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 产品展示 -->
      <div class="product_display">
        <div class="prodis_banner">
          <div class="pdbanner_title">{{ productTitle }}</div>
          <div class="pdbanner_english">Product display</div>
          <div class="product_list" @mouseover="overPro" @mouseleave="leavePro">
            <div class="swiper proSwiper">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in productList"
                  :key="index"
                  @click="productChange(item.id)"
                >
                  <div class="productlist_title">{{ item.name }}</div>
                  <div class="productlist_border"></div>
                  <div class="productlist_box">
                    <div class="graphical">
                      <img :src="item.imgUrl" alt="" />
                    </div>
                    <div class="data" v-html="item.content"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 企业资讯 -->
      <div class="corporate_news" @mouseover="overNews" @mouseleave="leaveNews">
        <div class="swiper-container cornews">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in corporateNewsList"
              :key="index"
            >
              <div class="left">
                <img :src="item.homePageImgUrl" />
              </div>
              <div class="right">
                <div class="title">{{ item.title }}</div>
                <div class="text">
                  {{ item.content }}
                  <br />
                  <span @click="arrowClick(item.id)">更多>>></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="partner">
        <div class="partner_banner">
          <div class="pb_title">{{ partnerTitle }}</div>
          <div class="pb_english">cooperative partner</div>
          <div class="list_box">
            <div
              class="pb_list"
              v-for="(item, index) in partnerList"
              :key="index"
            >
              <img :src="item.logoImgUrl" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="screenWidth <= 640" style="width: 100%">
      <MobileHome></MobileHome>
    </div>
  </div>
</template>

<script>
import {
  gotationImg,
  threeDimensional,
  modelBriefIn,
  product,
  partner,
  Enters,
} from "../api/modules/request";
import { baseURL } from "../api/baseUrl.js";
import Swiper from "swiper";
import "swiper/css/swiper.css";
import MobileHome from "../components/mobileHome.vue";
export default {
  components: {
    MobileHome,
  },
  data() {
    return {
      rotationList: null,
      purposeList: [
        require("../assets/image/zxsn.png"),
        require("../assets/image/kfcx.png"),
        require("../assets/image/jzfw.png"),
      ],
      dataTypeTitle: "星闪世图科技集团·缔造数字孪生的三维世界",
      dataTypeList: [{ content: "" }],
      solutionList: null,
      productTitle: "产品展示",
      productList: [],
      corporateNewsList: null,
      mySwiper: null,
      tbSwiter: null,
      partnerTitle: "合作伙伴",
      partnerList: null,
      screenWidth: null,
      currentIndex: 0,
      myProSwiper: null,
      wheelNum: null,
    };
  },
  created() {
    this.screenWidth = document.documentElement.clientWidth;
  },
  mounted() {
    this.mySwiper = new Swiper(".cornews", {
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      speed: 1000,
      observer: true,
      autoplay: {
        dalay: 0,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
    this.getRotationChart();
    this.getThreeData();
    this.getSolutionData();
    this.getProduct();
    this.getPartnerData();
    this.getCorporateNews();
  },
  methods: {
    // 获取到顶部的距离
    wheelTop() {
      this.wheelNum = this.$refs.piediv.getBoundingClientRect().top;
    },

    // 获取轮播图片
    getRotationChart() {
      gotationImg().then((res) => {
        res.data.forEach((item) => {
          item.imgUrl = baseURL + item.imgUrl.substring(1);
        });
        this.rotationList = res.data;
      });
    },

    // 获取三维世界数据
    getThreeData() {
      threeDimensional().then((res) => {
        res.data.forEach((e) => {
          e.imgUrl = baseURL + e.imgUrl.substring(1);
        });
        this.dataTypeList = res.data;
        const that = this;
        this.$nextTick(() => {
          this.tbSwiter = new Swiper(".tbSwiter", {
            slidesPerView: "auto",
            spaceBetween: 0,
            centeredSlides: true,
            loop: true,
            autoplay: {
              dalay: 5000,
            },
            on: {
              slideChangeTransitionEnd: function () {
                that.currentIndex = this.realIndex;
              },
            },
          });
        });
      });
    },

    // 鼠标进入三维世界
    overTb() {
      this.tbSwiter.autoplay.stop();
    },

    // 鼠标移出三维世界
    leaveTb() {
      this.tbSwiter.autoplay.start();
    },

    // 获取解决方案数据
    getSolutionData() {
      modelBriefIn().then((res) => {
        res.data.forEach((e) => {
          e.imgUrl = baseURL + e.imgUrl.substring(1);
        });
        this.solutionList = res.data;
      });
    },

    // 跳转到解决方案详情
    solutionBtn(val) {
      this.$router.push({
        path: "/solutionDetail",
        query: {
          id: val.id,
          type: val.digitalTwinsId - 1,
        },
      });
    },

    // 获取产品列表
    getProduct() {
      product().then((res) => {
        res.data.forEach((e) => {
          e.imgUrl = baseURL + e.imgUrl.substring(1);
        });
        this.productList = res.data;
        this.$nextTick(() => {
          this.myProSwiper = new Swiper(".proSwiper", {
            slidesPerView: 2,
            spaceBetween: 30,
            loop: true,
            autoplay: {
              dalay: 3000,
            },
          });
        });
      });
    },

    // 鼠标进入产品
    overPro() {
      this.myProSwiper.autoplay.stop();
    },

    // 鼠标离开产品
    leavePro() {
      this.myProSwiper.autoplay.start();
    },

    // 跳转到产品详情
    productChange(val) {
      this.$router.push({
        path: "/serviceDetails",
        query: {
          id: val,
        },
      });
    },

    // 获取企业资讯
    getCorporateNews() {
      Enters().then((res) => {
        var newArr = [];
        res.data.forEach((item) => {
          if (item.isHomePage == 1) {
            newArr.push(item);
          }
        });
        newArr.forEach((e) => {
          e.homePageImgUrl = baseURL + e.homePageImgUrl.substring(1);
        });
        this.corporateNewsList = newArr;
      });
    },

    // 鼠标进入企业资讯
    overNews() {
      this.mySwiper.autoplay.stop();
    },

    // 鼠标离开企业资讯
    leaveNews() {
      this.mySwiper.autoplay.start();      
    },

    // 跳转到企业资讯详情
    arrowClick(val) {
      this.$router.push({
        path: "/enterpriseInfoDetail",
        query: {
          id: val,
          type: 0,
        },
      });
    },

    // 获取合作伙伴
    getPartnerData() {
      partner().then((res) => {
        res.data.forEach((e) => {
          e.logoImgUrl = baseURL + e.logoImgUrl.substring(1);
        });
        this.partnerList = res.data;
      });
    },
  },
};
</script>

<style lang="less">
.home_box {
  width: 100%;

  .rotation_chart {
    width: 100%;
    max-height: 865px;
    position: relative;

    .cover {
      width: 100%;
      height: 97%;
      position: absolute;
      top: 0;
      z-index: 30;
    }
  }

  .purpose {
    width: 100%;
    margin: 50px 0 113px 0;

    .purpose_title {
      width: 100%;
      text-align: center;
      font-family: YouSheBiaoTiHei-2;
      font-size: 2.25rem;
      color: #333;

      .purpsoe_english {
        font-size: 1.1rem;
        color: #999;
        margin-bottom: 40px;
      }
    }

    .purpose_banner {
      width: 1200px;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;

      .pb_img {
        width: 375px;
        height: 280px;
      }
    }
  }

  .data_type {
    width: 100%;
    margin-bottom: 94px;

    .dt_banner {
      width: 1200px;
      margin: 0 auto;

      .tb_title,
      .tb_english {
        width: 100%;
        text-align: center;
        height: 50px;
        font-size: 2.25rem;
        color: #333333;
        text-align: center;
        font-family: YouSheBiaoTiHei-2;
      }

      .tb_english {
        color: #999;
        font-size: 1.1rem;
        margin: 10px 0 40px 0;
      }

      .tb_list {
        width: 100%;
        height: 420px;
        display: flex;
        justify-content: space-between;

        .tb_left {
          width: 800px;
          height: 100%;
          border-radius: 8px;

          .tbSwiter {
            width: 100%;
            height: 100%;

            .swiper-slide {
              display: -webkit-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              -webkit-justify-content: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;
              transition: 300ms;
              transform: scale(0.8);
              border-radius: 8px;

              img {
                max-width: 100%;
                max-height: 100%;
                border-radius: 8px;
              }
            }

            .swiper-slide {
              width: 46%;
            }

            .swiper-slide-active,
            .swiper-slide-duplicate-active {
              transform: scale(1);
            }
          }
        }

        .tb_right {
          width: 400px;
          height: 100%;
          position: relative;
          color: #666666;
          font-family: pf_cg;
          text-align: center;
          font-size: 1.1rem;

          .tb_content {
            width: 100%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            line-height: 45px;

            .names {
              font-size: 1.25rem;
              color: #0052d9;
              font-family: PingFang Medium;
            }
          }
        }
      }
    }
  }

  .solution {
    width: 100%;
    height: 750px;
    background: url(../assets/image/product_bgc.png) no-repeat;
    background-size: 100% 100%;
    padding-top: 102px;
    box-sizing: border-box;

    .solution_banner {
      margin: 0 auto;
      width: 1200px;
      cursor: pointer;

      .soluba_title {
        height: 40px;
        line-height: 40px;
        display: flex;

        div:nth-child(1) {
          width: 8px;
          height: 100%;
          background-color: #0052d9;
          margin-right: 10px;
        }

        div:nth-child(2) {
          margin: auto 0;
          font-size: 1.5rem;
          color: #ffffff;
          font-family: pf_cg;
          font-weight: 200;
        }
      }

      .soluba_box {
        width: 100%;
        height: 338px;
        display: flex;
        margin-top: 58px;

        .soluba_txt {
          width: 553px;
          height: 291px;
          margin-top: 24px;
          color: #a8a8a8;
          font-size: 1.1rem;
          overflow: auto;
          letter-spacing: 1px;
          line-height: 30px;
          font-family: pf_cg;
          text-indent: 2.2rem;
          line-height: 35px;
        }

        .solution_image {
          margin-left: 39px;
          height: 338px;
          width: 600px;
          border-radius: 10px;
          border: 1px solid #fff;

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }
      }
    }
  }

  .product_display {
    width: 100%;
    height: 530px;
    background-color: #fbfbfb;
    margin: 50px 0;
    padding: 30px 0 42px 0;
    box-sizing: border-box;

    .prodis_banner {
      width: 1200px;
      margin: 0 auto;

      .pdbanner_title {
        height: 62px;
        font-size: 2.25rem;
        color: #333333;
        font-family: YouSheBiaoTiHei-2;
        text-align: center;
      }

      .pdbanner_english {
        width: 100%;
        text-align: center;
        color: #999;
        font-size: 1.1rem;
        font-family: YouSheBiaoTiHei-2;
        margin-bottom: 20px;
      }

      .product_list {
        width: 1200px;
        overflow: hidden;

        .proSwiper {
          width: 100%;
          height: 100%;

          .swiper-slide {
            height: 376px;
            background-color: #fff;
            padding: 24px 0 0 20px;
            box-sizing: border-box;
            border-radius: 8px;

            .productlist_title {
              font-size: 1.25rem;
              height: 33px;
              width: 40px;
              white-space: nowrap;
              font-family: PingFang Medium;
            }

            .productlist_border {
              width: 2.5rem;
              height: 4px;
              background-color: #0052d9;
              margin-bottom: 42px;
              border-radius: 2px;
            }

            .productlist_box {
              display: flex;
              width: 590px;

              .graphical {
                width: 150px;
                height: 160px;
                margin-right: 40px;
                position: relative;

                img {
                  max-width: 100%;
                  max-height: 100%;
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                }
              }
            }

            .data {
              color: #666666;
              font-size: 1.05rem;
              width: 368px;
              height: 245px;
              overflow: auto;
              line-height: 32px;
              font-family: pf_cg;
              font-size: 1rem;

              pre {
                line-height: 32px;
                font-family: pf_cg;
                font-size: 1rem;
              }
            }

            .data:hover {
              color: #0052d9;
              font-family: PingFang Medium;
            }
          }
        }
      }
    }
  }

  .corporate_news {
    width: 100%;
    height: 550px;
    background: url(../assets/image/bgc.png) no-repeat;
    background-size: 100% 100%;

    .cornews {
      width: 100%;
      height: 550px;

      .swiper-slide {
        width: 100%;
        height: 550px;
        display: flex;

        .left {
          width: 50%;
          height: 550px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .right {
          width: 50%;
          height: 100%;
          padding: 120px 150px 0 150px;
          box-sizing: border-box;

          .title {
            color: #333333;
            font-size: 2.25rem;
            font-family: YouSheBiaoTiHei-2;
          }

          .text {
            color: #666666;
            font-size: 1.1rem;
            letter-spacing: 2px;
            margin-top: 40px;
            text-indent: 38px;
            font-family: pf_cg;
            line-height: 30px;

            span {
              display: inline-block;
              margin-top: 20px;
              margin-left: 500px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .partner {
    width: 100%;
    margin: 50px 0 20px 0;

    .partner_banner {
      width: 1200px;
      margin: 0 auto;

      .pb_title,
      .pb_english {
        width: 100%;
        text-align: center;
        color: #333333;
        font-size: 2.25rem;
        font-family: YouSheBiaoTiHei-2;
      }

      .pb_english {
        color: #999;
        font-size: 1.125rem;
        margin-bottom: 20px;
      }

      .list_box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .pb_list {
          width: 224px;
          height: 100px;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          margin-bottom: 30px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
