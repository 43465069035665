<template>
  <div class="m_solution">
    <div class="meau_list">
      <MobileHead></MobileHead>
    </div>
    <!-- 背景图 -->
    <div class="group_bgc">
      <img src="../assets/image/jufas.png" />
      <div class="ju_txt">解决放案</div>
      <div class="ju_eng">Solution</div>
    </div>
    <!-- 导航列表 -->
    <div class="nav_list">
      <ul>
        <li
          v-for="(item, index) in navList"
          :key="index"
          :class="[index == navNum ? 'add_border' : '']"
          @touchend="changeNav(item, index)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <!-- 主题 -->
    <div class="theme">
      <img :src="themeObj.imgUrl" />
      <div class="theme_txt">
        <div class="txt_title">{{ themeObj.name }}</div>
        <div class="txt_line"></div>
        <div class="txt_article">
          {{ themeObj.content }}
        </div>
      </div>
    </div>
    <!-- 数据列表 -->
    <div
      class="data_list"
      v-for="(item, index) in solutionList"
      :key="index"
      @click="goDetiail(item)"
    >
      <div class="title">{{ item.name }}</div>
      <div class="data_content">{{ item.content }}</div>
      <div class="data_img">
        <img :src="item.imgUrl" />
      </div>
    </div>
    <!-- 底部 -->
    <MobileFoot></MobileFoot>
  </div>
</template>

<script>
import MobileHead from "./mobileHead.vue";
import MobileFoot from "./mFoot.vue";
import { industryModel, modelBriefIn } from "../api/modules/request.js";
import { baseURL } from "../api/baseUrl.js";
export default {
  components: {
    MobileHead,
    MobileFoot,
  },
  data() {
    return {
      navNum: 0,
      navList: [],
      solutionList: null,
      themeObj: {
        imgUrl: "",
        name: "",
        content: "",
      },
    };
  },
  created() {},
  mounted() {
    this.getNavList();
  },
  watch: {
    $route(to, from) {
      this.navNum = Number(this.$route.query.type);
    },
  },
  methods: {
    // 获取列表菜单
    getNavList() {
      if (!this.$route.query.type) {
        this.navNum = 0;
        industryModel().then((res) => {
          res.data.forEach((item) => {
            item.imgUrl = baseURL + item.imgUrl.substring(1);
          });
          this.navList = res.data;
          console.log(this.navList);
          this.themeObj.imgUrl = this.navList[0].imgUrl;
          this.themeObj.name = this.navList[0].name;
          this.themeObj.content = this.navList[0].content;
          modelBriefIn({
            digitalTwinsId: this.navList[0].id,
          }).then((res) => {
            res.data.forEach((item) => {
              item.imgUrl = baseURL + item.imgUrl.substring(1);
            });
            this.solutionList = res.data;
          });
        });
      } else {
        this.navNum = Number(this.$route.query.type);
        industryModel().then((res) => {
          res.data.forEach((item) => {
            item.imgUrl = baseURL + item.imgUrl.substring(1);
          });
          this.navList = res.data;
          this.themeObj.imgUrl = this.navList[this.navNum].imgUrl;
          this.themeObj.name = this.navList[this.navNum].name;
          this.themeObj.content = this.navList[this.navNum].content;
          modelBriefIn({
            digitalTwinsId: Number(this.navNum) + 1,
          }).then((res) => {
            res.data.forEach((item) => {
              item.imgUrl = baseURL + item.imgUrl.substring(1);
            });
            this.solutionList = res.data;
          });
        });
      }
    },

    // 点击列表菜单
    changeNav(val, index) {
      modelBriefIn({
        digitalTwinsId: val.id,
      }).then((res) => {
        this.navNum = index;
        this.themeObj.imgUrl = this.navList[this.navNum].imgUrl;
        this.themeObj.name = this.navList[this.navNum].name;
        this.themeObj.content = this.navList[this.navNum].content;
        this.solutionList = res.data;
        this.solutionList.forEach((item) => {
          item.imgUrl = baseURL + item.imgUrl.substring(1);
        });
      });
    },

    // 点击数据列表
    goDetiail(val) {
      this.$router.push({
        path: "/solutionDetail",
        query: {
          id: val.id,
          type: this.navNum,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.m_solution {
  width: 100%;

  .meau_list {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5;
  }

  .group_bgc {
    width: 100%;
    height: 80rem;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .ju_txt,
    .ju_eng {
      position: absolute;
      color: #fff;
      font-size: 5.5rem;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-family: pf_cg;
    }

    .ju_eng {
      top: 60%;
      font-size: 4.5rem;
    }
  }

  .nav_list {
    width: 100%;
    padding: 0 2.5%;
    box-sizing: border-box;

    ul {
      width: 100%;
      display: flex;
      border-bottom: 0.5rem solid #e9e9e9;

      li {
        padding: 3.5rem 0;
        box-sizing: border-box;
        font-size: 4.2rem;
        font-family: PingFang Medium;
        color: #333;
        width: 18%;
        text-align: center;
        margin-right: 2.5%;
      }

      li:nth-child(5) {
        margin-right: 0;
      }

      .add_border {
        border-bottom: 0.8rem solid #0052d9;
      }
    }
  }

  .theme {
    width: 100%;
    height: 40rem;
    margin: 5rem 0 2rem 0;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .theme_txt {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding: 5rem 3rem 2rem 3rem;
      box-sizing: border-box;
      font-family: pf_cg;
      color: #fff;

      .txt_title {
        font-size: 6rem;
      }

      .txt_line {
        width: 12rem;
        height: 0.8rem;
        background-color: #fff;
        border-radius: 0.5rem;
        margin-bottom: 4rem;
      }

      .txt_article {
        font-size: 4rem;
      }
    }
  }

  .data_list {
    width: 100%;
    padding: 0 3%;
    box-sizing: border-box;
    margin-bottom: 3rem;

    .title {
      width: 100%;
      height: 8rem;
      line-height: 8rem;
      border-left: 1rem solid #0052d9;
      padding-left: 1rem;
      box-sizing: border-box;
      font-size: 5rem;
      font-family: PingFang Medium;
      color: #333;
      margin-bottom: 2rem;
    }

    .data_content {
      font-size: 4rem;
      color: #666666;
      font-family: pf_cg;
      line-height: 7.5rem;
    }

    .data_img {
      width: 100%;
      height: 65rem;
      margin-top: 1rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
