<template>
  <div class="platformDetailsBox">
    <div v-if="screenWidth > 640" style="width: 100%">
      <div v-html="content" class="detailsBox ql-snow ql-editor"></div>
      <!-- 返回上一级 -->
      <div class="back_product">
        <div class="product_txt" @click="backList">
          <img src="../assets/image/back.png" alt="" />
          <div class="txt">返回产品列表</div>
        </div>
      </div>
    </div>
    <div v-else-if="screenWidth <= 640" style="width: 100%">
      <MEnterpriseInfoDetail></MEnterpriseInfoDetail>
    </div>
  </div>
</template>

<script>
import { queryIndustry, queryEnterprise } from "../api/modules/request.js";
import { Loading } from "element-ui";
import MEnterpriseInfoDetail from "../components/mEnterpriseInfoDetail.vue";
export default {
  components: {
    MEnterpriseInfoDetail,
  },
  data() {
    return {
      content: "",
      loading: null,
      screenWidth: null
    };
  },
  created() {
    this.screenWidth = document.documentElement.clientWidth;
    this.loading = Loading.service({
      lock: true,
      text: "加载中……",
      background: "rgba(0, 0, 0, 0.2)",
    });
    if (Number(this.$route.query.type) === 0) {
      queryEnterprise({
        enterpriseNewsId: this.$route.query.id,
      }).then((res) => {
        if (res.data.length != 0) {
          this.content = res.data[0].content;
          this.loading.close();
        } else if (res.data.length == 0) {
          setTimeout(() => {
            this.loading.close();
            this.content = "暂无数据";
          }, 5000);
        }
      });
    } else {
      queryIndustry({
        industryNewsId: this.$route.query.id,
      }).then((res) => {
        if (res.data.length != 0) {
          this.content = res.data[0].content;
          this.loading.close();
        } else if (res.data.length == 0) {
          setTimeout(() => {
            this.loading.close();
            this.content = "暂无数据";
          }, 5000);
        }
      });
    }
  },
  mounted() {},
  methods: {
    // 返回上一级
    backList() {
      this.$router.push({
        path: "/enterpriseInfo",
        query: {
          type: this.$route.query.type,
        },
      });
    },
  },
};
</script>

<style lang="less">
.platformDetailsBox {
  width: 100%;

  .detailsBox {
    width: 1200px;
    margin: 100px auto 0 auto;
  }

   .back_product {
    width: 1200px;
    margin: auto;

    .product_txt {
      display: flex;
      margin: 20px 0 50px 0;

      .txt {
        width: 100%;
        line-height: 25px;
        text-align: left;
        color: #666666;
        font-size: 1.3rem;
        font-family: PingFang Medium;
        cursor: pointer;
        display: flex;
      }

      img {
        width: 19px;
        height: 15px;
        margin: 5px 5px 0 0;
      }
    }
  }
}
</style>
