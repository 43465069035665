import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/public.css'
import './assets/text/text.css'
import './utils/rem'
import scroll from 'vue-seamless-scroll'
import BaiduMap from 'vue-baidu-map'
import MintUI from 'mint-ui'

import 'mint-ui/lib/style.css'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueQuillEditor from 'vue-quill-editor' 
// import "zx-quill/dist/zx-quill.css";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import animate from 'animate.css'


Vue.use(animate)

Vue.use(ElementUI)
Vue.use(MintUI)
Vue.use(scroll)
Vue.use(VueQuillEditor)
Vue.use(BaiduMap, {
  ak: 'zvqbHEF0q4IhuK1dZ1HGu4WgSnqeWYUs'
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')