<template>
  <div class="mServiceDetails_box">
    <div class="meau_list">
      <MobileHead></MobileHead>
    </div>
    <!-- 背景图 -->
    <div class="group_bgc">
      <img src="../assets/image/3.png" />
    </div>
    <!-- 详情 -->
    <div v-html="content" class="content_box"></div>
  </div>
</template>

<script>
import MobileHead from "./mobileHead.vue";
import { queryProduct } from "../api/modules/request.js";
export default {
  components: {
    MobileHead,
  },
  data() {
    return {
      content: null,
    };
  },
  mounted() {
    this.getProductData();
  },
  methods: {
    // 获取产品详情
    getProductData() {
      queryProduct({
        productApplicationId: this.$route.query.id,
      }).then((res) => {
        if (res.data.length != 0) {
          this.content = res.data[0].content;
        } else if (res.data.length == 0) {
          setTimeout(() => {
            this.content = "暂无数据";
          }, 5000);
        }
      });
    },
  },
};
</script>

<style lang="less">
.mServiceDetails_box {
  width: 100%;
  padding-bottom: 20rem;

  .meau_list {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5;
  }

  .group_bgc {
    width: 100%;
    height: 80rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content_box {
    width: 100%;
    margin-top: 5rem;
    padding: 0 2.5%;
    box-sizing: border-box;
    font-family: pf_cg;
    margin-bottom: 20rem;

    p {
      font-size: 4.1rem !important;
    }

    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
</style>
