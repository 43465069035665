import {
    service,
    serviceForMock,
    request,
    requestForMock
} from '../http.js'

// 获取首页信息
export const getHome = query => {
    return request({
        url: '/home-page/details',
        method: 'get',
        params: query
    });
};

// 获取所有集团介绍
export const groupIntroduction = query => {
    return request({
        url: '/group-introduce',
        method: 'get',
        params: query
    });
}

// 获取轮播图片
export const gotationImg = query => {
    return request({
        url: '/carousel',
        method: 'get',
        params: query
    });
}

// 发展历程
export const history = query => {
    return request({
        url: '/history/all',
        method: 'get',
        params: query
    });
}

// 行业奖项
export const prize = query => {
    return request({
        url: '/industry-prize',
        method: 'get',
        params: query
    });
}

// 荣誉展示 
export const honor = query => {
    return request({
        url: '/honor-display',
        method: 'get',
        params: query
    });
}

// 获取产品列表
export const product = query => {
    return request({
        url: '/product-application',
        method: 'get',
        params: query
    });
}

// 产品简介
export const productInfo = query => {
    return request({
        url: '/product-application',
        method: 'get',
        params: query
    });
}

// 上传应聘信息
export const applyFor = query => {
    return request({
        url: '/candidate-info',
        method: 'post',
        data: query
    });
}

// 获取行业模块
export const industryModel = query => {
    return request({
        url: '/digital-twins',
        method: 'get',
        params: query
    });
}

// 获取解决方案简介
export const modelBriefIn = query => {
    return request({
        url: '/system-introduce',
        method: 'get',
        params: query
    });
}

// 简历导入
export const resume = query => {
    return request({
        url: '/candidate-info/resume',
        method: 'post',
        params: query
    });
}

// 获取所有招聘信息
export const recruitmentInfo = query => {
    return request({
        url: '/recruit',
        method: 'get',
        params: query
    });
}

// 获取所有公司
export const enterprise = query => {
    return request({
        url: '/company',
        method: 'get',
        params: query
    });
}

// 获取系统详情
export const groupDetails = query => {
    return request({
        url: '/system-detail',
        method: 'get',
        params: query
    });
}

// 获取三维世界数据
export const threeDimensional = query => {
    return request({
        url: '/3d-word',
        method: 'get',
        params: query
    });
}

// 获取所有合作伙伴
export const partner = query => {
    return request({
        url: '/partner',
        method: 'get',
        params: query
    });
}

// 获取企业动态
export const Enters = query => {
    return request({
        url: '/enterprise-news',
        method: 'get',
        params: query
    });
}

// 获取行业新闻
export const news = query => {
    return request({
        url: '/industry-news',
        method: 'get',
        params: query
    });
}

// 获取产品详情
export const queryProduct = query => {
    return request({
        url: '/product-detail',
        method: 'get',
        params: query
    });
}

// 获取企业动态详情
export const queryEnterprise = query => {
    return request({
        url: '/enterprise-news-detail',
        method: 'get',
        params: query
    });
}

// 获取行业新闻详情
export const queryIndustry = query => {
    return request({
        url: '/industry-news-detail',
        method: 'get',
        params: query
    });
}