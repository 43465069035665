<template>
  <div class="m_resumeDelivery">
    <div class="meau_list">
      <MobileHead></MobileHead>
    </div>
    <!-- 背景图 -->
    <div class="group_bgc">
      <img src="../assets/image/3.png" />
    </div>
    <!-- 岗位名称 -->
    <div class="post_name">
      <div class="name_box">
        <div class="name">{{ name }}</div>
        <div class="submits" @click="submitResume()">提交简历</div>
      </div>
    </div>
    <!-- 联系信息 -->
    <div class="contact_information">
      <div class="contact_title">联系信息</div>
      <div class="form_box">
        <!-- 姓名 -->
        <div class="name">姓名<span>*</span></div>
        <el-input v-model="contactForm.name" @blur="changeName"></el-input>
        <div v-if="nameValue == true" class="tips">请输入姓名</div>
        <!-- 电话 -->
        <div class="name">电话<span>*</span></div>
        <el-input v-model="contactForm.tel" @blur="changeTel"></el-input>
        <div v-if="telValue == true" class="tips">请输入正确的电话号码</div>
        <!-- 电子邮箱 -->
        <div class="name">电子邮箱<span>*</span></div>
        <el-input v-model="contactForm.mail" @blur="changeMail"></el-input>
        <div v-if="mailValue == true" class="tips">请输入正确的电子邮箱</div>
        <!-- 期望工作地点 -->
        <div class="name">期望工作地点<span>*</span></div>
        <el-input
          v-model="contactForm.workPlace"
          placeholder="可选地：无锡，北京，西安，杭州，福建，新疆"
          @blur="changeWorkPlac"
        ></el-input>
        <div v-if="workPlaceValue == true" class="tips">请选择期望工作地</div>
        <!-- 其他信息 -->
        <div class="name">自我评价</div>
        <el-input
          type="textarea"
          :rows="5"
          v-model="contactForm.textarea"
        ></el-input>
        <!-- 其他信息 -->
        <div class="name" style="margin-bottom: 4rem">作品或其他附件</div>
        <div class="upload_file">
          <el-upload
            class="upload-demo"
            :action="resumeUrl"
            :on-success="handleSuccessChange"
            :before-upload="beforeUpload"
            :file-list="fileList"
            :limit="1"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MobileHead from "./mobileHead.vue";
import { baseURL } from "../api/baseUrl.js";
import { applyFor, resume } from "../api/modules/request.js";
export default {
  components: {
    MobileHead,
  },
  data() {
    return {
      name: null,
      contactForm: {
        name: "",
        tel: "",
        mail: "",
        workPlace: "",
        joinUsId: null,
        textarea: "",
        resumeAnnexUrl: null,
      },
      nameValue: false,
      telValue: false,
      mailValue: false,
      workPlaceValue: false,
      fileList: [],
      resumeUrl: baseURL + "candidate-info/resume",
    };
  },
  created() {
    let data = this.$route.query.val;
    this.name = JSON.parse(data).jobName;
    this.contactForm.joinUsId = JSON.parse(data).id;
  },
  methods: {
    // 姓名
    changeName() {
      if (this.contactForm.name == "") {
        this.nameValue = true;
      } else {
        this.nameValue = false;
      }
    },

    // 电话
    changeTel() {
      const tel = /^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/;
      if (tel.test(this.contactForm.tel) && this.contactForm.tel != "") {
        this.telValue = false;
      } else {
        this.telValue = true;
      }
    },

    // 邮箱
    changeMail() {
      const mail = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      if (mail.test(this.contactForm.mail) && this.contactForm.mail != "") {
        this.mailValue = false;
      } else {
        this.mailValue = true;
      }
    },

    // 期望工作地点
    changeWorkPlac() {
      if (this.contactForm.workPlace == "") {
        this.workPlaceValue = true;
      } else {
        this.workPlaceValue = false;
      }
    },

    // 附件上传之前
    beforeUpload(file) {
      // const fileSzie = file.size / 1024 / 1024 < 2;
      // if (!fileSzie) {
      //   this.$message({
      //     message: "文件太大，不能超过2M",
      //     type: "danger",
      //   });
      // }
      // return fileSzie;
    },

    // 上传附件成功
    handleSuccessChange(file) {
      if (file.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        this.contactForm.resumeAnnexUrl = file.data;
      } else {
        this.$message({
          message: "上传失败",
          type: "error",
        });
      }
    },

    // 提交简历
    submitResume() {
      if (
        this.contactForm.name == "" ||
        this.contactForm.tel == "" ||
        this.contactForm.mail == "" ||
        this.contactForm.workPlace == "" ||
        this.contactForm.resumeAnnexUrl == null
      ) {
        this.$message({
          message: "请填写个人信息以及上传附件简历",
          type: "warning",
        });
      } else {
        applyFor({
          content: this.contactForm.textarea,
          name: this.contactForm.name,
          joinUsId: this.contactForm.joinUsId,
          mail: this.contactForm.mail,
          resumeAnnexUrl: this.contactForm.resumeAnnexUrl,
          tel: this.contactForm.tel,
          workPlace: this.contactForm.workPlace,
        }).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "简历投递成功",
              type: "success",
            });
            this.$router.push("/about");
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.m_resumeDelivery {
  width: 100%;

  .meau_list {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5;
  }

  .group_bgc {
    width: 100%;
    height: 80rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .post_name {
    width: 100%;
    padding: 0 3%;
    box-sizing: border-box;

    .name_box {
      height: 14rem;
      width: 100%;
      border-bottom: 1px solid #979797;
      display: flex;
      font-family: PingFang Medium;
      justify-content: space-between;

      .name {
        font-size: 4.5rem;
        line-height: 14rem;
        color: #333333;
      }
      .submits {
        width: 30%;
        text-align: center;
        font-size: 3.8rem;
        height: 10rem;
        line-height: 10rem;
        color: #ffffff;
        background-color: #0052d9;
        margin-top: 2rem;
      }
    }
  }

  .contact_information {
    width: 100%;
    margin-top: 4rem;
    padding: 0 3%;
    box-sizing: border-box;

    .contact_title {
      font-size: 4rem;
      font-family: pf_cg;
      color: #666666;
    }

    .form_box {
      width: 100%;

      .name {
        font-size: 4rem;
        font-family: pf_cg;
        margin-top: 5rem;

        span {
          color: #0052d9;
        }
      }

      .tips {
        font-size: 3rem;
        font-family: pf_cg;
        color: #ff204c;
      }

      .upload_file {
        margin-bottom: 40rem;
      }
    }
  }
}
</style>
