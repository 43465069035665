<template>
  <div class="mobile_box">
    <div class="list_icon" @touchend="meauBtn">
      <img src="../assets/image/meau.png" />
    </div>
    <div class="title">星闪世图科技集团</div>
    <div
      v-if="shrink"
      @touchend="meauClose"
      class="shrink_meau"
      :class="[shrink == true ? 'change_meau' : '']"
    >
      <div class="meau_list">
        <ul>
          <li
            v-for="(item, index) in meauList"
            :key="index"
            @touchend="jumpRouter(item, index)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shrink: false,
      meauList: [
        {
          name: "首页",
          herf: "/home",
        },
        {
          name: "集团简介",
          herf: "/groupProfile",
        },
        {
          name: "产品应用",
          herf: "/service",
        },
        {
          name: "解决方案",
          herf: "/solution",
        },
        {
          name: "企业资讯",
          herf: "/enterpriseInfo",
        },
        {
          name: "关于我们",
          herf: "/about",
        },
      ],
    };
  },
  methods: {
    // 点击菜单
    meauBtn() {
      this.shrink = true;
    },

    // 关闭菜单列表
    meauClose() {
      this.shrink = false;
    },

    // 菜单跳转
    jumpRouter(val, index) {
      console.log(val)
      this.$router.push(val.herf);
    },
  },
};
</script>

<style lang="less" scoped>
.mobile_box {
  width: 100%;
  height: 10rem;
  line-height: 10rem;
  background-color: rgba(0, 0, 0, 0.5);

  .list_icon {
    position: absolute;
    left: 2%;
    top: 1rem;
    width: 6%;
    height: 8rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    color: #fff;
    font-size: 5.5rem;
    width: 100%;
    text-align: center;
    font-family: PingFang Medium;
  }

  .shrink_meau {
    width: 100%;
    height: 100vh;
    position: absolute;
    left: -40%;
    top: 0;
    background-color: rgba(0, 0, 0, 0);

    .meau_list {
      width: 40%;
      height: 100vh;
      background-color: #fff;
      padding: 5rem 0 0 0;
      box-sizing: border-box;

      li {
        width: 100%;
        line-height: 11rem;
        text-align: center;
        font-family: pf_cg;
        font-size: 3.5rem;
      }
    }
  }

  .change_meau {
    animation: moWalk .5s forwards alternate;
  }
  @keyframes moWalk {
    from {
      left: -40%;
    }
    to {
      left: 0;
    }
  }
}
</style>
