<template>
  <div class="product_box">
    <div v-if="screenWidth > 640" style="width: 100%">
      <!-- banner图 -->
      <div class="banner_img">
        <img src="../assets/image/cpyy.png" />
      </div>
      <!-- 产品列表 -->
      <div class="product_list">
        <ul>
          <li
            v-for="(item, index) in productList"
            :key="index"
            @click="productClick(item, index)"
          >
            <div
              class="li_box"
              @mouseenter="liEnter(index)"
              @mouseleave="liLeave"
              :class="[liIndex == index ? 'libox_change' : 'libox_changeover']"
            >
              <div class="imgBox">
                <el-image :src="item.imgUrl" fit="scale-down"></el-image>
                <div class="texts">{{ item.name }}</div>
              </div>
              <div class="text_box" v-html="item.content"></div>
              <div class="more">查看产品详情 ></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else-if="screenWidth <= 640" style="width: 100%">
      <MService></MService>
    </div>
  </div>
</template>

<script>
import { productInfo } from "../api/modules/request.js";
import { baseURL } from "../api/baseUrl.js";
import MService from "../components/mService.vue";
export default {
  components: {
    MService,
  },
  data() {
    return {
      productList: null,
      screenWidth: null,
      liIndex: null,
    };
  },
  created() {
    this.screenWidth = document.documentElement.clientWidth;
  },
  mounted() {
    this.getProductData();
  },
  methods: {
    // 获取产品列表
    getProductData() {
      productInfo().then((res) => {
        res.data.forEach((e) => {
          e.imgUrl = baseURL + e.imgUrl.substring(1);
        });
        this.productList = res.data;
      });
    },

    // 查看产品详情
    productClick(item, index) {
      this.$router.push({
        path: "/serviceDetails",
        query: {
          id: item.id,
        },
      });
    },

    // 鼠标进入
    liEnter(index) {
      this.liIndex = index;
    },

    // 鼠标移除
    liLeave() {
      this.liIndex = null;
    },
  },
};
</script>

<style lang="less">
.product_box {
  width: 100%;

  .banner_img {
    width: 100%;
    height: 450px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .product_list {
    width: 1200px;
    margin: 50px auto;

    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        width: 590px;
        height: 350px;
        padding: 20px 0 0 0;
        box-sizing: border-box;

        .li_box {
          width: 100%;
          height: 100%;
          display: flex;
          border-radius: 10px;
          position: relative;
          color: #666;
          cursor: pointer;
          box-shadow: 0 0 10px rgba(173, 173, 173, 0.1);

          .imgBox {
            width: 180px;
            height: 100%;
            margin-left: 20px;
            position: relative;

            .el-image {
              width: 100%;
              height: 300px;
            }

            .texts {
              position: absolute;
              color: #0052d9;
              font-size: PingFang Medium;
              text-align: center;
              bottom: 70px;
              left: 50%;
              width: 100%;
              transform: translateX(-50%);
            }
          }

          .text_box {
            margin-left: 30px;
            width: 360px;
            height: 100%;
            font-size: 0.9rem;
            line-height: 35px;
            overflow: auto;
            font-family: PingFang Medium;
            padding: 60px 0 0 0;
            box-sizing: border-box;
          }

          .more {
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            font-family: PingFang Medium;
            font-size: 0.9rem;
          }
        }

        .libox_change {
          animation: libox 0.5s forwards alternate;
        }

        @keyframes libox {
          from {
            transform: scale(1);
          }
          to {
            transform: scale(1.03);
            color: #0052d9;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
          }
        }

        .libox_changeover {
          animation: liboxover 0.8s forwards alternate;
        }

        @keyframes liboxover {
          from {
            transform: scale(1.03);
            color: #0052d9;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
          }
          to {
            transform: scale(1);
          }
        }
      }
    }
  }
}
</style>
