<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script >
export default{
  data(){
    return{}
  },
  created(){
    sessionStorage.setItem("token",this.$route.query.token || null)
  },
  beforeDestroy(){
    sessionStorage.clear();
  }
}
</script>
<style lang="less">
</style>
