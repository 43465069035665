<template>
  <div class="m_about">
    <div class="meau_list">
      <MobileHead></MobileHead>
    </div>
    <!-- 背景图 -->
    <div class="group_bgc">
      <img v-if="navNum == 0" src="../assets/image/gywmmo.png" />
      <div v-if="navNum == 0" class="about_txt">关于我们</div>
      <div v-if="navNum == 0" class="about_eng">About Us</div>
      <img v-if="navNum == 1" src="../assets/image/jrmo.png" />
      <div v-if="navNum == 1" class="about_txt">加入我们</div>
      <div v-if="navNum == 1" class="about_eng">Join Us</div>
    </div>
    <!-- 导航列表 -->
    <div class="nav_list">
      <ul>
        <li
          v-for="(item, index) in navList"
          :key="index"
          :class="[index == navNum ? 'add_border' : '']"
          @touchend="changeNav(item, index)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <!-- 岗位需求 -->
    <div class="post_box" v-if="navNum == 0">
      <el-collapse
        @change="handleChange"
        v-for="(item, index) in contactUsList"
        :key="index"
      >
        <el-collapse-item :title="item.jobName" :name="index">
          <div class="job_responsibilities">
            <div class="job">岗位职责</div>
            <div v-html="item.jobDescription" class="responsibilities"></div>
          </div>
          <div class="job_requirements">
            <div class="job">任职要求</div>
            <div v-html="item.requirements" class="responsibilities"></div>
          </div>
          <div class="salary">
            <div class="job">薪资待遇</div>
            <div v-html="item.salary" class="responsibilities"></div>
          </div>
          <div class="delivery_box">
            <div class="delivery" @click="delivery(item)">立即投递</div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- 加入我们 -->
    <div class="join_us" v-else-if="navNum == 1">
      <el-collapse
        @change="handleChange"
        v-for="(item, index) in joinUsList"
        :key="index"
      >
        <el-collapse-item :title="item.companyName" :name="index">
          <div class="m_contacts"><span>联系人:</span> {{ item.contacts }}</div>
          <div class="location"><span>地址:</span> {{ item.location }}</div>
          <div class="mail"><span>E-mail:</span> {{ item.mail }}</div>
          <div class="tel"><span>联系电话:</span> {{ item.tel }}</div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- 底部 -->
    <MobileFoot></MobileFoot>
  </div>
</template>

<script>
import MobileHead from "./mobileHead.vue";
import MobileFoot from "./mFoot.vue";
import { recruitmentInfo, enterprise } from "../api/modules/request.js";
export default {
  components: {
    MobileHead,
    MobileFoot,
  },
  data() {
    return {
      navNum: 0,
      navList: ["加入我们", "联系我们"],
      contactUsList: null,
      joinUsList: [
        {
          contacts: "惠女士",
          location: "江苏省无锡经济开发区海岸城41号29层",
          companyName: "无锡星闪智能科技有限公司",
          tel: "0510-81005882",
          mail: "huijun@new3s.com.cn",
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.id == undefined) {
      this.navNum = 0;
      this.getRecruitmentInfo();
      this.getenterprise();
    } else {
      this.navNum = this.$route.query.id;
      this.getRecruitmentInfo();
      this.getenterprise();
    }
  },
  methods: {
    // 点击列表菜单
    changeNav(val, index) {
      this.navNum = index;
    },

    // 工作岗位折叠面板
    handleChange(val) {
      console.log(val);
    },

    // 获取所有的招聘信息
    getRecruitmentInfo() {
      recruitmentInfo().then((res) => {
        this.contactUsList = res.data;
      });
    },

    // 获取所有集团公司
    getenterprise() {
      enterprise().then((res) => {
        this.joinUsList = res.data;
      });
    },

    // 立即投递
    delivery(val) {
      let obj = {
        id: val.id,
        jobName: val.jobName,
      };
      this.$router.push({
        path: "/resumeDelivery",
        query: {
          val: JSON.stringify(obj),
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.m_about {
  width: 100%;

  .meau_list {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5;
  }

  .group_bgc {
    width: 100%;
    height: 80rem;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .about_txt,
    .about_eng {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: pf_cg;
      font-size: 5.5rem;
    }

    .about_eng {
      top: 60%;
      font-size: 4.5rem;
    }
  }

  .nav_list {
    width: 100%;
    padding: 0 3%;
    box-sizing: border-box;

    ul {
      width: 100%;
      display: flex;
      border-bottom: 0.5rem solid #e9e9e9;

      li {
        padding: 3.5rem 0;
        box-sizing: border-box;
        font-size: 4.2rem;
        font-family: PingFang Medium;
        color: #333;
        width: 18%;
        text-align: center;
        margin-right: 2.5%;
      }

      .add_border {
        border-bottom: 0.8rem solid #0052d9;
      }
    }
  }

  .post_box {
    width: 100%;
    padding: 0 3%;
    box-sizing: border-box;
    margin: 5rem 0;

    .job_responsibilities,
    .job_requirements,
    .salary {
      width: 100%;
      margin-bottom: 6rem;

      .job {
        line-height: 5rem;
        font-size: 4rem;
        border-left: 1rem solid #0052d9;
        padding-left: 0.5rem;
        box-sizing: border-box;
        font-family: PingFang Medium;
        margin-bottom: 2rem;
      }

      .responsibilities {
        width: 100%;
        font-size: 3.5rem;
        font-family: pf_cg;
      }
    }

    .delivery_box {
      width: 100%;
      margin-bottom: 4rem;

      .delivery {
        width: 35%;
        height: 10rem;
        background-color: #0052d9;
        text-align: center;
        line-height: 10rem;
        color: #fff;
        font-size: 4rem;
        font-family: pf_cg;
        margin-left: 32.5%;
      }
    }
  }

  .join_us {
    width: 100%;
    padding: 0 3%;
    box-sizing: border-box;
    margin-top: 4rem;
    color: #333;
    font-family: PingFang Medium;
    margin-bottom: 5rem;

    .m_contacts {
      line-height: 6rem;
      border-left: 1rem solid #0052d9;
      font-size: 3.5rem;
      padding-left: 1rem;
      box-sizing: border-box;
      margin-bottom: 2rem;

      span {
        display: inline-block;
        width: 15%;
      }
    }

    .location,
    .mail,
    .tel {
      line-height: 6rem;
      font-size: 3.5rem;
      padding-left: 1rem;
      box-sizing: border-box;
      margin-bottom: 2rem;

      span {
        display: inline-block;
        width: 15%;
      }
    }
  }
}
</style>
