<template>
  <div class="realTimeInfo">
    <div v-if="screenWidth > 640" style="width: 100%">
      <!-- banner -->
      <div class="banner">
        <img src="../assets/image/qyzx.png" alt="" />
      </div>
      <!-- 资讯列表 -->
      <div>
        <div class="realTimeInfoClass">
          <ul>
            <li
              v-for="(item, index) in realTimeInfoList"
              :key="index"
              :class="liChange == index ? 'liChange' : ''"
              @click="changeLi(index)"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="dynamic">
          <div
            class="dynamicList"
            v-for="(item, index) in enterpriseList"
            :key="index"
            @click="enterpriseDetail(item)"
          >
            <div class="image">
              <img :src="item.imgUrl" />
            </div>
            <div class="textBox">
              <div class="topTitle">{{ item.title }}</div>
              <div class="brief">{{ item.content }}</div>
              <div class="date">{{ item.publishDate }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="screenWidth <= 640" style="width: 100%">
      <MEnterpriseInfo></MEnterpriseInfo>
    </div>
  </div>
</template>

<script>
import { Enters, news } from "../api/modules/request.js";
import { baseURL } from "../api/baseUrl.js";
import MEnterpriseInfo from "../components/mEnterpriseInfos.vue";
export default {
  components: {
    MEnterpriseInfo,
  },
  data() {
    return {
      realTimeInfoList: ["企业动态", "行业新闻"],
      liChange: 0,
      enterpriseList: [],
      screenWidth: null,
    };
  },
  created() {
    this.screenWidth = document.documentElement.clientWidth;
    this.liChange = Number(this.$route.query.type);
    if (!this.$route.query.type) {
      this.liChange = 0;
      this.getEnter();
    } else {
      this.liChange = Number(this.$route.query.type);
      if (this.liChange === 0) {
        this.getEnter();
      } else {
        this.getnews();
      }
    }
  },
  watch: {
    $route(to, from) {
      // this.liChange = Number(this.$route.query.type);
      this.changeLi(Number(this.$route.query.type));
    },
  },
  methods: {
    // 点击资讯列表
    changeLi(index) {
      this.liChange = index;
      if (index === 0) {
        this.getEnter();
      } else {
        this.getnews();
      }
    },

    // 获取企业动态
    getEnter() {
      Enters().then((res) => {
        res.data.forEach((e) => {
          e.imgUrl = baseURL + e.imgUrl.substring(1);
        });
        this.enterpriseList = res.data;
      });
    },

    // 获取行业新闻
    getnews() {
      news().then((res) => {
        res.data.forEach((e) => {
          e.imgUrl = baseURL + e.imgUrl.substring(1);
        });
        this.enterpriseList = res.data;
      });
    },

    // 跳转到详情
    enterpriseDetail(val) {
      this.$router.push({
        path: "/enterpriseInfoDetail",
        query: {
          id: val.id,
          type: this.liChange,
        },
      });
    },
  },
};
</script>

<style lang="less">
.realTimeInfo {
  width: 100%;
  position: relative;

  .banner {
    width: 100%;
    height: 450px;
    margin-top: 80px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .realTimeInfoClass {
    height: 70px;
    width: 100%;
    padding: 0 360px;
    box-sizing: border-box;
    border-bottom: 2px solid #e4e7ed;
    margin-bottom: 58px;
    position: relative;

    ul {
      width: 100%;
      display: flex;

      li {
        width: 140px;
        height: 70px;
        line-height: 70px;
        font-size: 1.25rem;
        margin-right: 72px;
        position: relative;
        transition: 0.2s all linear;
        cursor: pointer;
        text-align: center;
        color: #666666;
        font-family: pf_cg;
      }

      li::before {
        content: "";
        position: absolute;
        top: 0;
        left: 100%;
        width: 0;
        height: 100%;
        border-bottom: 4px solid #0052d9;
        transition: 0.2s all linear;
      }

      li:hover::before {
        width: 140px;
        top: 0;
        left: 0;
        transition-delay: 0.1s;
        border-bottom-color: #0052d9;
        z-index: -1;
      }

      li:hover ~ li::before {
        left: 0;
      }

      li:nth-child(6) {
        margin-right: 0;
      }

      .liChange {
        color: #333333;
        border-bottom: 4px solid #0052d9;
        font-family: PingFang Medium;
      }
    }
  }

  .dynamic,
  .news {
    width: 100%;
    padding: 0 360px;
    box-sizing: border-box;

    .dynamicList {
      display: inline-block;
      width: 375px;
      height: 500px;
      margin: 0 37.5px 50px 0;
      background-color: #f9f9f9;
      transition: all 0.2s ease;
      -moz-transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      cursor: pointer;

      .image {
        width: 100%;
        height: 250px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .textBox {
        width: 100%;
        height: 250px;
        padding: 18px 10px;
        box-sizing: border-box;
        overflow: auto;
        position: relative;

        .topTitle {
          width: 100%;
          color: #333333;
          font-size: 1.2rem;
          margin-bottom: 18px;
          font-family: pf_cg;
        }

        .brief {
          color: #666666;
          font-size: 1rem;
          margin-bottom: 20px;
          font-family: pf_cg;
        }

        .date {
          color: #666666;
          font-size: 1rem;
          font-family: pf_cg;
          position: absolute;
          bottom: 20px;
        }
      }
    }

    .dynamicList:hover {
      transform: translateY(-10px);
      box-shadow: 0px 0px 5px rgb(192, 192, 192);

      .textBox {
        // background-color: #0052d9;
        background: -webkit-linear-gradient(top, #0052d9, #96d3ff);
      }

      .topTitle {
        color: #fff;
      }

      .brief,
      .date {
        color: #fff;
      }
    }

    .dynamicList:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
